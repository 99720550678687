import {IonCheckbox, IonRadioGroup} from "@ionic/react";
import {TGText} from "../TGElements";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPackage,setAutoRenew} from "../../redux/slices/membershipSlice";
import {env} from "../../helpers";
import React from "react";

const SubscriptionItem = ({item}) => {
    const {selectedPackage, autoRenew} = useSelector(state => state.membership)
    const dispatch = useDispatch()

    const onClickHandler = () => {
        dispatch(setSelectedPackage(item))
    }

    return (
        <>
            <div className={`SubscriptionItem ${selectedPackage?.id === item.id && "selected"}`} onClick={onClickHandler}>
                <div>
                    <p className={"SubscriptionInfo"}>{item?.name}</p>
                    <div className={"Badge subscription"}> {t("subscription")}</div>
                </div>

                <TGText className={"SubscriptionPrice"} fontSize={24} fontWeight={600}>
                    {item?.cost + env("CURRENCY_SYMBOL")}
                </TGText>
            </div>

            {selectedPackage?.id === item.id && (
                <IonRadioGroup className={"CheckboxWrapper"} onIonChange={(e) => dispatch(setAutoRenew(e.detail.checked))}>
                    <IonCheckbox checked={autoRenew} labelPlacement={"end"}>
                        {t('autoRenew-text')}
                    </IonCheckbox>
                </IonRadioGroup>
            )}

        </>
    );
};

export default SubscriptionItem;
