import TGInput from './TGInput';

const TGSearchInput = ({
  margin,
  label,
  type,
  placeholder = "",
  onSearchTextChange,
  value,
  onInputFocus,
  onInputBlur,
  borderRadius,
  lastIcon
}) => {
  return (
    <TGInput
      label={label}
      type={type}
      value={value}
      placeholder={placeholder}
      onInputChange={onSearchTextChange}
      margin={margin}
      onInputFocus={onInputFocus ? onInputFocus : null}
      onInputBlur={onInputBlur ? onInputBlur : null}
      borderRadius={borderRadius}
      lastIcon={lastIcon}
    />
  );
};

export default TGSearchInput;