//React
import { useEffect, useState } from 'react'
//Redux
import { useSelector } from 'react-redux'
//Components
import {  TGImg, TGText } from '../TGElements'
//Languag
import { t } from 'i18next'

const NoEntries = ({path}) => {
  const [image, setImage] = useState(null)
  
  const setNoEntryImage = async () => {
      try {
        const img = await import(`../../assets/images/no-${path}.png`)
        img && setImage(img.default)
      } catch (e) {
        return
      }
  }

  useEffect(() => {
    setNoEntryImage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return (
    <div className='NoEntries'>
      <TGImg src={image}/>
      <TGText fontSize={16} fontWeight={"semibold"}>
        {t(`no-${path}-title`)}
      </TGText>
      <TGText fontSize={14} fontWeight={"regular"} color={"var(--tg-secondary-text)"}>
        {t(`no-${path}-description`)}
      </TGText>
    </div>
  )
}

export default NoEntries