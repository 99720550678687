import {t} from "i18next";
import {api} from "../../helpers";
import MembershipVehiclesTab from "./MembershipVehiclesTab";
import {TGIcon} from "../TGElements";
import {useDispatch, useSelector} from "react-redux";
import {setMembershipDetail, setUserVehicles} from "../../redux/slices/membershipSlice";
import {useIonViewDidEnter} from "@ionic/react";
import {useEffect} from "react";

const ParkDetailContainer = () => {
    const {selectedPark,membershipDetail} = useSelector(state => state.membership)
    const dispatch = useDispatch()
    const getMembershipDetail = () => {
        api.getMembershipDetail(selectedPark).then((r) => {
            let data = r?.data.data;
            if (data) {
                data.userVehicles && dispatch(setUserVehicles(formatVehicles(data.userVehicles)))
                dispatch(setMembershipDetail({
                    availableQuota: data.availableQuota,
                    freeQuota: data.freeQuota,
                    subscriptionCount: data.subscriptionCount,
                    vehicleCount: data.vehicleCount
                }))
            }
        })
    }
    useEffect(() => {
        if (selectedPark) {
            getMembershipDetail()
        }
    }, [selectedPark])

    const formatVehicles = (userVehicles) => {
        let formatted = [];
        userVehicles.forEach((value) => {
            value.vehicle.model_text = value.vehicle.model;
            value.vehicle.uid = value.id;
            value.vehicle.type = value.type;
            value.vehicle.availableUntil = value.subscription?.availableUntil;
            value.vehicle.isPassive = value.subscription?.terminatedAt;
            delete value.vehicle.model;
            formatted.push(value.vehicle)
        })
        return formatted
    }

    return (selectedPark &&
        <div className={"ParkDetailContainer"}>
            <div className={"parkDetailSummaryContainer"}>
                <p className={"title"}>{t("parking-rights-details")}</p>


                <p className={"summary"}>{t("free")}:
                    <span> {membershipDetail ? membershipDetail.freeQuota : 0}</span>
                    <span className={"seperator"}></span>{t("subscription")}:
                    <span> {membershipDetail ? membershipDetail.subscriptionCount : 0}</span>
                </p>
                <div style={{display:"flex"}}>
                    <TGIcon style={{"marginTop":"4px", marginRight:"3px"}} name="info-blue" width={16} height={16} />
                    <p className={"info"}> *{t("you-can-add-total-x-vehicles", {count: membershipDetail ? membershipDetail.availableQuota : 0})}</p>
                </div>

            </div>


            <div className={"ParkDetailTabsContainer"}>
                <p className={`active`}>
                    {t("vehicles")}
                </p>
            </div>

            <MembershipVehiclesTab getMembershipDetail={getMembershipDetail}/>
        </div>

    );
};

export default ParkDetailContainer;
