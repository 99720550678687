import React from 'react';
import {TGIcon, TGText} from "../../TGElements";
import {LottiePlayer} from "../../index";
import {isUserAbleToStartSession, isUserLoggedIn, startScan} from "../../../helpers";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ScanButton = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const onClick = async () => {
    if (!isUserLoggedIn()) return history.push("/register-phone")
    const isAble = await isUserAbleToStartSession()
    if(!isAble) return;
    const response = await startScan();
    if (!response.scanner) {
      return history.push("/start-session");
    }
    history.push("/scanner");
  }


  return (
    <div className="TGMapButtonWrapper">
      <TGIcon name="anniversary" width={98} height={26} margin="0 0 15px 0" fill="#ce0024"/>
      <div onClick={onClick} className={"TGMapScanButton"}>
        <TGIcon name={"new-scan"} width={38} height={38}/>
        <LottiePlayer animationName={"scan-animation"} loop cssClass={"TGMapScanAnimation"}/>
      </div>
      <TGText fontSize={16} fontWeight={700} margin={"0 0 8px"}>
        {t('map-start-charge-btn')}
      </TGText>
    </div>
  );
};

export default ScanButton;