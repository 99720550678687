import {useTranslation} from "react-i18next";
import TGImg from "../TGElements/TGImg";
import {EmptyPlateVehicle} from "../../assets/images";
import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {TGModal} from "../TGElements";
import {plateValidation} from "../../helpers";

const MembershipAddVehicleModal = ({ isAddVehicleModalOpen, setIsAddVehicleModalOpen, plate, setPlate, buttonHandler }) => {
    const {t} = useTranslation()
    const { keyboardHeight } = useSelector((state) => state.device);
    const modalRef = useRef(null);
    const changeHandler = (e) => {
      const plate = e.target.value.toUpperCase().trim();
      setPlate(plate);
    }

    useEffect(() => {
      modalRef.current.setCurrentBreakpoint(keyboardHeight > 0 ? 1 : 0.65);

    }, [keyboardHeight]);


    return (
      <TGModal
        reference={modalRef}
        className={"TGModal"}
        isOpen={isAddVehicleModalOpen}
        breakpoints={[0.65, 1]}
        initialBreakpoint={0.65}
        setIsOpen={setIsAddVehicleModalOpen}
        modalCloseButton={true}
        iosSafeArea={false}
        toolbar={true}
        fullScreen={false}
        modalHandler={false}
      >
        <div className={"addVehicleModal"}>
          <p>{t("add-plate")}</p>
          <p>{t("enter-the-licence-plate-of-the-vehicle")}</p>
          <div style={{position: "relative"}}>
            <TGImg src={EmptyPlateVehicle}/>
            <div className={"vehicleImgLabel"}>{plate}</div>
          </div>
          <div className={"enterPlateWrapper"}>
            <p style={{padding: "0 0 0 10px",marginLeft:0, fontSize: "16px", fontWeight: 500}}>{t("enter-plate")}</p>
            <input className={"vehicleInput"} maxLength={8} value={plate} onChange={changeHandler} type="text"/>
          </div>

          <button className={"addButton"} onClick={buttonHandler}>{t("save")}</button>
        </div>
      </TGModal>
    );
};

export default MembershipAddVehicleModal;