import { IonItem } from "@ionic/react";
import {TGIcon, TGImg, TGText} from "../../TGElements";
import {setDefaultVehicle} from "../../../helpers";

const Vehicle = ({vehicle}) => {

    const handleSetDefault = async () => {
        await setDefaultVehicle(vehicle.id)
    }

    return (
        <IonItem className={`MyVehicle MyVehicleItem ${vehicle.is_default ? "DefaultVehicle" : ""}`}>
            <div>
                <TGImg
                    src={vehicle.image}
                    width={130}
                    height={'auto'}
                    reflect={true}
                    cssClass="VehicleImage"
                />
                <div>
                    <TGImg src={vehicle.logo} size={40} cssClass={'brandLogo'}/>
                    <div>
                        <TGText
                            fontSize={14}
                            fontWeight={"medium"}
                            color={"var(--tg-secondary-text)"}
                            wrapWithDots={true}
                        >
                            {vehicle.brand_name}
                        </TGText>
                        <TGText
                            fontSize={14}
                            fontWeight={"medium"}
                            width={120}
                            wrapWithDots={true}
                        >
                            {vehicle.model_text}
                        </TGText>
                    </div>
                    <div
                        style={{
                            border: "1px solid var(--tg-inactive)",
                            width: 150,
                            padding: "8px 0",
                            borderRadius: 8,
                            marginTop: 8,
                        }}
                    >
                        <TGText
                            margin={"0 auto"}
                            textTransform={"uppercase"}
                            fontSize={20}
                            fontWeight={"bold"}
                        >
                            {vehicle.plate}
                        </TGText>
                    </div>
                </div>

                <div className={'vehicleDefaultButton'} onClick={handleSetDefault}>
                    <TGIcon name={vehicle.is_default ? 'star-outline-green' : 'star-outline'}/>
                </div>
            </div>
        </IonItem>
    );
};

export default Vehicle;
