import {TGButton, TGImg} from "../TGElements";
import {NoParkSearch} from "../../assets/images";
import {t} from "i18next";
import SubscriptionItem from "./SubscriptionItem";
import {useDispatch, useSelector} from "react-redux";
import {api, env, plateValidation} from "../../helpers";
import {setAllPackages} from "../../redux/slices/membershipSlice";
import {addDays, format} from "date-fns";
import {Alert} from "../../helpers/alert";
import {useIonRouter, useIonViewDidEnter} from "@ionic/react";

const Subscriptions = () => {
    const {
        selectedPark,
        allPackages,
        selectedPackage,
        membershipPlate,
        autoRenew
    } = useSelector(state => state.membership)
    const dispatch = useDispatch()
    const today = new Date()
    const history = useIonRouter()

    const getAllSubscriptions = () => {
        api.getMembershipAllPackages(selectedPark).then((res) => {
            if (res.data.success) {
                dispatch(setAllPackages(res.data.data))
            }
        })
    }

    useIonViewDidEnter(() => {
        if (selectedPark) {
            getAllSubscriptions()
        }
    }, [selectedPark]);

    const startSubscription = () => {
        if (!membershipPlate || !plateValidation(membershipPlate)) {
            Alert.error(t("valid-plate"))
            return
        }

        if (!selectedPackage) {
            Alert.error(t("select-package"))
            return
        }

        api.addMembershipPackage(selectedPark, selectedPackage.id, membershipPlate, autoRenew)
            .then((res) => {
                if (res.data.success) {
                    history.push('/menu/membership','back','pop')
                }
            })
    }


    return (
        <div className={"ParkDetailTabSubscriptions"}>
            {allPackages && allPackages.length ?
                allPackages.map((item) => (
                    <SubscriptionItem key={item.id} item={item}/>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg margin={20} src={NoParkSearch}/>
                    <p>{t("no-packages")}</p>
                </div>
            }

            <div className={"BottomBtn"}>
                {selectedPackage && (
                    <>
                        <div>
                            <div className={"SubscriptionInfo"}>
                                <span>{t('membership-start-date')}</span>
                                <span>: </span>
                                <b>{format(today, 'dd MMM yyy - hh:mm')}</b>
                            </div>
                            <div className={"SubscriptionInfo"}>
                                <span>{t('membership-end-date')}</span>
                                <span>: </span>
                                <b>{format(addDays(today, selectedPackage.days), 'dd MMM yyy - hh:mm')}</b>
                            </div>
                        </div>

                        <div className={"TotalPrice"}>
                            <span>{t('total')}</span><b>{selectedPackage?.cost + env("CURRENCY_SYMBOL")}</b>
                        </div>
                    </>
                )}
                <TGButton onButtonClick={startSubscription}>{t("start-subscription")}</TGButton>
            </div>

        </div>
    );
};

export default Subscriptions;
