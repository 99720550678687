import {useHistory} from "react-router"
import {CallCustomerServices, TGIcon, TGItem, TGList, TGPage} from "../components"
import {t} from 'i18next'
import uniqid from "uniqid";

const Support = () => {
    const history = useHistory()
    const SupportMenuItems = [
        {id: uniqid(), slug: "faq", leftIcon: {width: 19.2, height: 15.7}},
        {id: uniqid(), slug: "tickets", leftIcon: {width: 19.2, height: 17.2}},
        {id: uniqid(), slug: "send-ticket", leftIcon: {width: 17.2, height: 17.2}},
    ]
    const LeftIcon = ({...props}) => {
        return (
            <div className='TGItemIconWrapper'>
                <TGIcon {...props}/>
            </div>
        )
    }
    return (
        <TGPage
            title={t("support")}
            scrollY={false}
            backButton={true}
            ionPadding={false}
            cssClass={"ion-padding-start ion-padding-end"}
        >
            <TGList className="Menu">
                {SupportMenuItems.map(item => (
                    <TGItem
                        key={item.id}
                        slug={item.slug}
                        handleClick={() => history.push(`/menu/support/${item.slug}`)}
                        leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width}
                                            height={item.leftIcon.height}/>}
                    />
                ))}
            </TGList>
            <CallCustomerServices/>
        </TGPage>
    )
}

export default Support