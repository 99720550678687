import {Capacitor} from "@capacitor/core";
import {useSelector} from "react-redux";
import Stories from 'react-insta-stories';
import React, {useEffect, useState} from "react";
import Api from "../../../../helpers/api";
import {redirectUrl} from "../../../../helpers";

const MapFilterButtons = () => {

    const isNative = Capacitor.isNativePlatform()
    const isIos = Capacitor.getPlatform() === "ios"
    const [showStories, setShowStories] = useState(false)
    const [storyPreviews, setStoryPreviews] = useState([])
    const [stories, setStories] = useState()
    const [currentIndex,setCurrentIndex] = useState(0)

    const {mapLoading} = useSelector(state => state.global)
    const {searchModal} = useSelector(state => state.modal)

    const seeMoreClickHandler = (items) => {
        if (items[0].link) redirectUrl(items[0].link)
    }


    useEffect(() => {
        const api = new Api(31000, false)
        api.getStories().then(({data}) => {
            if (data?.length > 0) {
                setStoryPreviews(data)
                setStories(
                    data.map((item, index) => {
                        return {
                            url: item.items[0].src,
                            header: {
                                heading: item.name,
                                img: item.photo
                            },
                            seeMore: () => {},
                            seeMoreCollapsed: () => {
                                return (
                                    <div
                                        onClick={() => seeMoreClickHandler(item.items)}
                                        className={`SeeMoreButtonWrapper ${!item.items[0].link && 'passive'}`}
                                    >
                                        <div className={"upside-arrow-icon"}></div>
                                        <div className={"SeeMoreButton"}>
                                            {item.items[0].linkText}
                                        </div>
                                    </div>
                                )
                            },
                        }
                    })
                )
            }
        })
    }, []);


    return (
        <>
            <div className={`MapFilterButtons ${isNative && isIos ? "ios" : ""} ${!mapLoading.status && !searchModal.status ? "active" : ""}`}>
                <div className={"StorySliderContainer"}>
                    <div className={"StorySlider"}>
                        {storyPreviews.length > 0 && storyPreviews.map((storyPreview, index) => {
                            return (
                                <div key={index} className={`StorySliderItem`}>
                                    <div
                                        onClick={() => {
                                            setCurrentIndex(index)
                                            setShowStories(true)
                                        }}
                                        className={"StorySliderItemImageWrapper"}>
                                        <img
                                            width={"100%"}
                                            height={"100%"}
                                            src={storyPreview.photo}
                                            alt="story-preview"
                                        />
                                    </div>
                                    <div className={"StorySliderItemText"}> {storyPreview.name} </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <Stories
                defaultInterval={2500}
                stories={stories ?? [{url: ''}]}
                storyContainerStyles={{
                    paddingTop: "var(--ion-safe-area-top, 0)",
                    position: 'absolute',
                    zIndex: 9999,
                    transform: showStories ? "translateY(0)" : "translateY(100vh)",
                    transition: "transform 0.3s ease",
                    left: 0,
                    top: 0
                }}
                storyStyles={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100vw",
                }}
                header={(story)=>{
                    return (
                        <div className={"StoryHeader"}>
                            <div className={"StoryHeaderImageWrapper"}>
                                <img src={story.img} alt="story-header"/>
                            </div>
                            <span className={"StoryHeaderText"}>{story.heading}</span>
                        </div>
                    )
                }}
                currentIndex={currentIndex}
                onStoryEnd={(story) => setCurrentIndex(currentIndex)}
                isPaused={!showStories && currentIndex !== 0}
                onAllStoriesEnd={() => setShowStories(false)}
                width={"100%"}
                height={"100%"}
            />
        </>
    );
}

export default MapFilterButtons;
