//components
import {TGText} from './index'
//helpers
import {useTranslation} from 'react-i18next';

const TGList = ({title, className, children}) => {
  const {t} = useTranslation()
  return (
    <div className={`TGList ${className}`}>
      {title ? <TGText fontSize={14} fontWeight={"semibold"} color={"var(--tg-primary-text)"} padding={"16px 0 0 0"}>
        {t(title)}
      </TGText> : null}
      <div className='List'>
        {children}
      </div>
    </div>
  );
}

export default TGList
