//React
//Redux
import { useSelector } from "react-redux";
//Helpers
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
//Components
import { TGPage, TGList, TGText, TGIcon, TGItem } from "../components";
import { settings } from "../components/Menu/MenuContent/MenuItems";


const Settings = () => {
  const { push } = useHistory();
  const {language} = useSelector(state => state.user)
  const { t } = useTranslation();

  const RightIcon = (slug) => (
    <div>
      {slug === "change-language" ? (
        <TGText fontSize={15} fontWeight="medium" color={"var(--tg-gray-2)"}>
          {t(language.name.toLowerCase())}
        </TGText>
      ) : null}
      <TGIcon name={"right-arrow"} fill={"#F3F4F4"} />
    </div>
  )
  const LeftIcon = ({...props}) => {
    return (
      <div className='TGItemIconWrapper'>
        <TGIcon {...props}/>
      </div>
    )
  }
  return (
    <TGPage
      title={t("settings")}
      scrollY={false}
      backButton={true}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end"}
    >
      <TGList className="Menu">
        {
          settings.map(item => (
            <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => push(`/menu/settings/${item.slug}`)}
            rightIcon={<RightIcon />}
            leftIcon={<LeftIcon name={item.slug} size={19.5} />}
            />
          ))
        }
      </TGList>
    </TGPage>
  );
};

export default Settings;
