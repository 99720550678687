const LogoMono = (props) => {
  if(props.type === 'vertical') {
    <svg {...props} id="icon-mono-toger-logo" viewBox="0 0 202.37 43.6">
      <g id="toger_logo" transform="translate(-11313 -4009.76)">
        <path
          id="Path_192289"
          data-name="Path 192289"
          d="M1277.783,2750.394h39.861l-7.438,9.189h-7.9v33.124h-9.189v-33.124h-7.9l-7.438-9.189Zm62.562,0a21.261,21.261,0,1,1-21.261,21.261,21.261,21.261,0,0,1,21.261-21.261Zm0,9.05a12.21,12.21,0,1,0,12.21,12.211,12.21,12.21,0,0,0-12.21-12.211Zm61.9-9.05h-18.654l0,.027a21.26,21.26,0,0,0,1.062,42.494c5.953,0,11.534-3.072,15.3-7.223a22.956,22.956,0,0,0,5.475-10.847,8.749,8.749,0,0,0,.131-1.4l.025-3.559h-24.629l7.438,9.189h5.286c-.315.993-3.8,4.868-9.031,4.868-5.44,0-12.284-5.5-12.284-12.284a12.287,12.287,0,0,1,9.977-12.067v0h12.465l7.438-9.189Zm38.554,0h-33.419v9.189h25.98l7.439-9.189Zm29.671,30.409a16.161,16.161,0,0,0-7.589-30.429q-.415,0-.827.021h-19.81l7.438,9.189h13.781v.023a6.963,6.963,0,0,1,0,13.88v.024h-21.22v19.2h9.189V2782.4h8.474l8.425,10.308,11.821-.055-9.684-11.849Zm-53.9,2.888v-7.5h16.792L1440.8,2767h-33.419v25.875h25.98l7.439-9.188Z"
          transform="translate(10035.217 1259.387)"
          fill="#191e26"
          fillRule="evenodd"
        />
      </g>
    </svg>
  }
  return (
    <svg {...props} id="icon-mono-toger-logo" viewBox="0 0 202.37 43.6">
      <g id="toger_logo" transform="translate(-11313 -4009.76)">
        <path
          id="Path_192289"
          data-name="Path 192289"
          d="M1277.783,2750.394h39.861l-7.438,9.189h-7.9v33.124h-9.189v-33.124h-7.9l-7.438-9.189Zm62.562,0a21.261,21.261,0,1,1-21.261,21.261,21.261,21.261,0,0,1,21.261-21.261Zm0,9.05a12.21,12.21,0,1,0,12.21,12.211,12.21,12.21,0,0,0-12.21-12.211Zm61.9-9.05h-18.654l0,.027a21.26,21.26,0,0,0,1.062,42.494c5.953,0,11.534-3.072,15.3-7.223a22.956,22.956,0,0,0,5.475-10.847,8.749,8.749,0,0,0,.131-1.4l.025-3.559h-24.629l7.438,9.189h5.286c-.315.993-3.8,4.868-9.031,4.868-5.44,0-12.284-5.5-12.284-12.284a12.287,12.287,0,0,1,9.977-12.067v0h12.465l7.438-9.189Zm38.554,0h-33.419v9.189h25.98l7.439-9.189Zm29.671,30.409a16.161,16.161,0,0,0-7.589-30.429q-.415,0-.827.021h-19.81l7.438,9.189h13.781v.023a6.963,6.963,0,0,1,0,13.88v.024h-21.22v19.2h9.189V2782.4h8.474l8.425,10.308,11.821-.055-9.684-11.849Zm-53.9,2.888v-7.5h16.792L1440.8,2767h-33.419v25.875h25.98l7.439-9.188Z"
          transform="translate(10035.217 1259.387)"
          fill="#191e26"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default LogoMono;
