import {TGImg, TGText} from "../../TGElements";
import {
    OnboardingBgMap,
    OnboardingMapPointFirst, OnboardingMapPointSecond,
    OnboardingMapPointThird,
} from "../../../assets/onboarding";
import {useTranslation} from "react-i18next";

const Slide2 = ({activeIndex}) => {
    const {t} = useTranslation()

    return (
        <div className='OnboardingSlide'>
            <div className='OnboardingSlideContent'>
                <TGImg src={OnboardingBgMap} cssClass={"OnboardingParkingArea"}/>

                <div className={`OnboardingMapPoints ${activeIndex === 0 && "animate"}`}>
                    <TGImg src={OnboardingMapPointFirst} cssClass={"OnboardingMapPointFirst"} />
                    <TGImg src={OnboardingMapPointSecond} cssClass={"OnboardingMapPointSecond"} />
                    <TGImg src={OnboardingMapPointThird} cssClass={"OnboardingMapPointThird"} />
                </div>
            </div>
            <div className='OnboardingSlideTextContent'>
                <TGText
                    fontSize={20}
                    fontWeight={"semibold"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    margin={"36px 0 14px 0"}
                    width={"100%"}
                >
                    {t("select-station")}
                </TGText>
                <TGText
                    fontSize={16}
                    fontWeight={"regular"}
                    color={"var(--tg-primary-text)"}
                    textAlign={"center"}
                    width={"70%"}
                    textTransform={"unset"}
                >
                    {t("select-station-text")}
                </TGText>
            </div>
        </div>
    );
}

export default Slide2;
