//React
import { useEffect, useState } from "react";
//Redux
import {useDispatch, useSelector} from "react-redux";
//Components
import { ElapsedTimer } from "../../../TimeCounters";
import {TGButton, TGIcon, TGText} from "../../../TGElements";
import {setSessionInfoModal} from "../../../../redux/slices/modalSlice";
import {env} from "../../../../helpers";
import {useTranslation} from "react-i18next";

const SessionTop = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [elapsedTime, setElapsedTime] = useState(0);
  const {activeSession} = useSelector(state => state.session)
  const isActive = activeSession?.total_consumption > 0

  useEffect(() => {
    setElapsedTime(
      activeSession?.duration ?? 0
    );
  }, [activeSession]);


  return (
    <div className={"SessionTopWrapper"}>
      <div className={"TopLabels"}>
        <div className="top-animated-consumption">
          <TGText fontSize={26} width={"auto"} textAlign={"right"} fontWeight={"bold"} textTransform={"none"}>
            {activeSession.total_consumption?? '0'} kw
          </TGText>

          <div className={"charge-speed"}>
            <TGIcon name="charge-speed-noBg" size={16} cssClass={"charge-speed"}/>
            <TGText fontSize={16} fontWeight={"medium"} textTransform={"unset"}>
              {activeSession.kwh ?? '0'} {t('charge-speed-kw')}
            </TGText>
          </div>
        </div>

        <div className={"divider"}></div>

        <ElapsedTimer
          seconds={elapsedTime}
          setSeconds={setElapsedTime}
          isActive={true}
          classname={"ElapsedTimeText"}
        />
        <TGButton
          width={40}
          height={40}
          backgroundColor={"var(--tg-transparent)"}
          backgroundActiveColor={"var(--tg-transparent)"}
          onButtonClick={() => dispatch(setSessionInfoModal(true))}
          cssClass={"SessionInfoButton"}
        >
          <TGIcon name="session-info" size={22} />
        </TGButton>

      </div>

      <div className={`BottomLabelsWrapper ${isActive && 'active'}`}>
        {isActive ? (
          <>
            <div>
              <TGIcon name="CHaMoade-plug" size={22} margin={"0 5px 0 0"}/>
              <TGText fontSize={12} textAlign={"right"} fontWeight={600}>
                {activeSession?.connection}
              </TGText>
              <div className={"divider"}/>
              <TGText fontSize={12} color={"var(--tg-gray-2)"}>
                {t('connector-type',{type: activeSession?.connector_type})}
              </TGText>
            </div>

            <div>
              <TGText fontSize={14} textAlign={"right"} fontWeight={600} color={"#000"}>
                {activeSession.unit_price}{env('CURRENCY_SYMBOL')}
              </TGText>
              <span className={"circle-icon"}></span>
              <TGText fontSize={11} textAlign={"right"} fontWeight={500} color={"#000"}>
                {t('active-text')}
              </TGText>
            </div>
          </>
        ) : (
          <div>
            <TGIcon name="session-info" size={22} fill={"#000"}/>
            <TGText fontSize={12} fontWeight={600} margin={'0 0 0 8px'}>
              {t('no-charge-text')}
            </TGText>
          </div>
        )}
      </div>

    </div>
  )
};

export default SessionTop;
