import {createSlice} from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: "userSlice",
    initialState: {
        user: {
            token: "",
            info: {
                profile_photo_url: ""
            },
            isFirst: true,
        },
        language: {
            code: "tr",
            name: "Turkish"
        },
        vehicles: [],
        invoices: [],
        notifications: [],
        country: {
            countryName: "Turkey",
            countryPhoneCode: "90",
            countryCode: "TR",
        },
        paymentMethods: [],
        balance: 0,
        registerPagesNeverAppear: false,
        isRequestAppRate: false,
        vehicleViewFirstVisit: true,
    },
    reducers: {
        setUser: (state, action) => {
            const {email, first_name, last_name, profile_photo_url, token, email_permission, sms_permission} = action.payload
            if (email || first_name || last_name || profile_photo_url || email_permission || sms_permission) {
                state.user.info = action.payload
            } else if (token) {
                state.user.token = token
            } else {
                return;
            }
        },
        setVehicles: (state, action) => {
            state.vehicles = action.payload
        },
        setIsFirst: (state, action) => {
            state.user.isFirst = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setInvoices: (state, action) => {
            state.invoices = action.payload
        },
        setCountry: (state, action) => {
            state.country = action.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        },
        setIsRequestedAppRate: (state, action) => {
            state.isRequestAppRate = action.payload
        },
        setRegisterPagesNeverAppear: (state, action) => {
            state.registerPagesNeverAppear = action.payload
        },
        resetSlice: (state) => {
            state.user = {
                token: "",
                info: {},
                isFirst: false,
            }
            state.registerPagesNeverAppear = false
            state.vehicles = []
            state.vehicleViewFirstVisit = true
        },
        setVehicleViewFirstVisit: (state, action) => {
            state.vehicleViewFirstVisit = action.payload
        }
    },
})

export const {
    setUser,
    setVehicles,
    setIsFirst,
    setLanguage,
    setInvoices,
    setCountry,
    resetSlice,
    setNotifications,
    setPaymentMethods,
    setIsRequestedAppRate,
    setRegisterPagesNeverAppear,
    setBalance,
    setVehicleViewFirstVisit
} = userSlice.actions
export default userSlice.reducer
