//Components
import { TGIcon } from "../../../TGElements";
import ActionButton from "./ActionButton";
import SessionHeader from "./SessionHeader";
import SessionHero from "./SessionHero";

const SessionContent = ({setIntervalStart}) => {
  
  return (
    <div className="SessionContent">
      <SessionHeader/>
      <SessionHero />
      <ActionButton setIntervalStart={setIntervalStart}/>
    </div>
  );
};

export default SessionContent;
