import React from 'react'
import { TGButton, TGInput, TGText, TGTextarea } from '../TGElements'
import {t} from 'i18next'
import { sendContactForm } from '../../helpers'
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";

const ContactForm = () => {
    const history = useHistory()
    const { register, handleSubmit, formState: {errors}, reset } = useForm();

    const onSubmit = async data => {
        const {success} = await sendContactForm(data)
        if(success){
          history.replace("/menu/support/tickets")
        }
      }

  return (
    <form className='TicketForm' onSubmit={handleSubmit(onSubmit)}>
        <TGInput
          label={t("subject")}
          borderRadius={10}
          margin={"0 0 0.4rem 0"}
          error={!!errors.title}
          errorText={errors.title?.message}
          register={register("title", { required: t(`please-enter-valid-title`), minLength: { value: 3, message: t("min-length-title", {length: 3}) }})}
          />
        <TGTextarea
        placeholder={t("message")}
        counter={true}
        error={!!errors.message}
        errorText={errors.message?.message}
        register={register("message", { required: t(`please-enter-valid-message`), minLength: { value: 25, message: t("min-length-message", {length: 25}) }})}
        />
        <TGButton type="submit" margin={"1rem 0 0 0"}
        additionalStyle={{
          position: "absolute",
          bottom: "2rem",
          width: "calc(100% - 32px)"
        }}>
          <TGText color={"var(--tg-white)"}>
              {t("submit")}
          </TGText>
        </TGButton>
      </form>
  )
}

export default ContactForm