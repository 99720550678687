import { IonList } from '@ionic/react'
import { useSelector } from 'react-redux'
import MyVehicle from './MyVehicle'
import { NoEntries } from '../..'
const MyVehicleList = () => {
  const {vehicles} = useSelector(state => state.user)
  return (
    <IonList>
      {vehicles.length ? (
        vehicles.map((vehicle, index) => (
          <MyVehicle isFirstItem={index === 0} vehicle={vehicle} key={vehicle.id}/>
        ))
      ) : (
        <NoEntries path={"vehicles"} />
      )}
    </IonList>
  );
}

export default MyVehicleList
