import { useEffect, useState } from 'react';
import {OnboardingSwiper, TGPage, TGImg} from '../components';
import { useDispatch } from 'react-redux';
import { setIsFirst } from '../redux/slices/userSlice';
import {ArrowLeftIcon} from "../assets/onboarding";
import {useHistory} from "react-router";

const Onboarding = () => {
    const [activeIndex, setActiveIndex] = useState(0)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if(activeIndex === 3) {
            dispatch(setIsFirst(false))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    const NextButton = () => (
        <span className={"OnboardingNextButton"} onClick={() => history.push('/register-phone')}>
            <TGImg src={ArrowLeftIcon}/>
        </span>
    )

    return (
        <TGPage toolbar={false} scrollY={false} refresher={false} cssClass={"Onboarding"} ionPadding={false}>
            <div>
                <OnboardingSwiper setActiveIndex={setActiveIndex} activeIndex={activeIndex}/>
            </div>

            {activeIndex === 3 && <NextButton />}
        </TGPage>
    );
}

export default Onboarding;
