import AddNewButton from './AddNewButton'
import MyVehicleList from './MyVehicleList'
import {AddNewVehicleModal} from "../AddNewVehicle";

const MyVehicles = () => {
  return (
    <div className='MyVehicles'>
      <MyVehicleList />
      <AddNewButton />
      <AddNewVehicleModal isEdit={true}/>
    </div>
  )
}

export default MyVehicles
