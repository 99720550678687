import React, {useEffect, useRef, useState} from "react";
import {api} from "../../helpers";
import {t} from "i18next";
import PackageDetail from "./packageDetail";
import {Swiper, SwiperSlide} from "swiper/react";

const Packages = ({amount, setAmount, selectedPackage, setSelectedPackage}) => {
    const [balancePackages, setBalancePackages] = useState(null);
    const swiperRef = useRef();
    const getPackages = () => {
        api.getWalletPackages().then(({data}) => {
            if (data) {
                setBalancePackages(data);
                setSelectedPackage(data[0])
            }
        })
    }

    useEffect(() => {
        getPackages()
    }, [])

    useEffect(() => {
        if (selectedPackage) {
            setAmount(selectedPackage.balance)
        }
    }, [selectedPackage])


    return (
        <div className={"SelectAmounts"}>
            <p className={"ContainerTitle"}>{t("earn-and-buy")}</p>
            <p className={"ContainerSubTitle"} style={{marginTop: "-10px"}}>{t("wallet-campaing-message")}</p>
            <div className={"Packages"}>
                {balancePackages ?
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={3.6}
                        ref={swiperRef}
                    >
                        {balancePackages.map((balancePackage, index) => {
                            const isActive = selectedPackage && balancePackage && selectedPackage.id === balancePackage.id;
                            return (
                                <SwiperSlide
                                    onClick={() => {
                                        setSelectedPackage(balancePackage);
                                        swiperRef.current.swiper.slideTo(index - 1);
                                    }}
                                    key={index}
                                    className={`Package ${isActive ? "active" : ""}`}
                                    style={{
                                        color: isActive ? "white" : "#939598",
                                        fontWeight: isActive ? 700 : 400,
                                    }}
                                >
                                    {balancePackage.discountedPrice ?? balancePackage.originalPrice}₺
                                </SwiperSlide>
                            );
                        })}
                    </Swiper> : null
                }
            </div>

            {selectedPackage ?
                <PackageDetail selectedPackage={selectedPackage} amount={amount}/>
                : null
            }
        </div>
    )
};

export default Packages;
