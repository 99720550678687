import {TGText, TGButton, TGPage} from "../../TGElements";
import {useSelector} from "react-redux";
import {editVehicle, getVehicles, saveMyCar} from "../../../helpers";
import VehiclePreviewAnimated from "./VehiclePreviewAnimated";
import {useTranslation} from "react-i18next";
import {useIonRouter} from "@ionic/react";
import {useHistory} from "react-router-dom";

const VehiclePreview = () => {
  const router = useIonRouter();
  const history = useHistory()
  const {t} = useTranslation();
  const {newPlate, model, brand} = useSelector((state) => state.newCar);
  const isEdit = history.location.state && history.location.state.isEdit;
  const from = history.location.state && history.location.state.from;

  const saveCar = async () => {
    if (!isEdit) {
      const vehicle = {
        plate: newPlate,
        model_id: model.id,
        brand_id: model.brand_id,
      };
      const success = await saveMyCar(vehicle);

      if (success) {
        if (from === "menu/vehicles/add-vehicle") {
          router.push('/menu/vehicles/add-vehicle', 'back', 'pop')
        }else {
          router.push('/menu/vehicles', 'back', 'pop')
        }
      }else {
        router.push('/menu/vehicles/enter-plate', 'back', 'pop',{error: true})
      }
    } else {
      const success = await editVehicle();
      if (success) {
        getVehicles();
        router.push('/menu/vehicles', 'back', 'pop')
      }
    }

  };
  return (
    <TGPage
      title={t("your-car")}
      toolbar={true}
      scrollY={false}
    >
      <VehiclePreviewAnimated vehicleModel={model} vehicleBrand={brand}/>
      <hr style={{width: 100, backgroundColor: "var(--tg-inactive)"}}/>
      <TGText
        color={"var(--tg-primary-text)"}
        fontWeight={"bold"}
        fontSize={20}
        margin={"10px auto"}
        textTransform={"uppercase"}
      >
        {newPlate}
      </TGText>
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          width: "calc(100% - 32px)",
        }}
      >
        <TGButton onButtonClick={() => saveCar()}>
          <TGText color={"var(--tg-white)"} fontWeight={"semibold"}>
            {t("save-my-car")}
          </TGText>
        </TGButton>
        <div className="TGButton" onClick={() => history.goBack()}>
          <TGText color={"var(--tg-secondary-text)"}>{t("not-my-car")}</TGText>
        </div>
      </div>
    </TGPage>
  );
};

export default VehiclePreview;
