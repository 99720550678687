import React from 'react'
import {TGIcon, TGItem, TGList, TGPage} from '../components'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import {PaymentPageItems} from '../assets/Payments'

const Payments = () => {
    const {push} = useHistory()
    const {t} = useTranslation()
    return (
        <TGPage
            title={t("payments")}
            scrollY={false}
            backButton={true}
        >
            <TGList>
                {PaymentPageItems.map(item => (
                    <TGItem
                        key={item.id}
                        slug={item.slug}
                        handleClick={() => push(`/menu/payments/${item.slug}`)}
                        leftIcon={<TGIcon name={item.slug} size={42} fill={'var(--tg-primary-text)'}/>}
                    />
                ))}
            </TGList>
        </TGPage>
    )
}

export default Payments