// Capacitor
import {Capacitor} from "@capacitor/core";
import {Geolocation} from "@capacitor/geolocation";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import {NativeSettings, AndroidSettings, IOSSettings} from 'capacitor-native-settings';
import {PushNotifications} from '@capacitor/push-notifications';
//Redux
import {store} from "../redux/store";
import {setPermissions} from "../redux/slices/deviceSlice";
import {Alert} from "./alert";
//Helpers
import {t} from 'i18next'
import {editUser} from "./apiFunctions";

export const getPermissions = async (type) => {
    const user = store.getState().user?.user.info;
    switch (type) {
        case "location":
            const location = await Geolocation.checkPermissions();
            if (location.location !== "granted") {
                store.dispatch(setPermissions({type: "location", status: false}));
                return false
            } else {
                store.dispatch(setPermissions({type: "location", status: true}));
                return true
            }
        case "push_permission":
            PushNotifications.checkPermissions().then(
                result => {
                    if (result.receive === "granted") {
                        PushNotifications.register()
                        store.dispatch(setPermissions({type: "push_permission", status: true}));
                        return true
                    } else {
                        store.dispatch(setPermissions({type: "push_permission", status: false}));
                        return false
                    }
                }
            ).catch(err => {
                store.dispatch(setPermissions({type: "push_permission", status: false}));
                return false
            });
            break
        case "sms_permission":
            let sms_permission = !!(user && user.sms_permission);
            store.dispatch(setPermissions({type: "sms_permission", status: sms_permission}));

            return sms_permission
        case "email_permission":
            let email_permission = !!(user && user.email_permission);
            store.dispatch(setPermissions({type: "email_permission", status: email_permission}));
            return email_permission
        default:
            const camera = await BarcodeScanner.checkPermission();
            if (camera.granted) {
                store.dispatch(setPermissions({type: "camera", status: true}));
                return true
            }
            break;
    }
};
const openSettings = () => {
    NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
    });
}
const handleCameraPermission = {
    web: async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true})
            stream.getTracks().forEach(track => track.stop())
            if (stream.id) {
                store.dispatch(setPermissions({type: "camera", status: true}))
            } else {
                store.dispatch(setPermissions({type: "camera", status: false}))
            }
        } catch (error) {
            store.dispatch(setPermissions({type: "camera", status: false}))
        }
    },
    native: async () => {
        const {camera} = store.getState().device.permissions
        if (!camera) {
            const cameraPer = await BarcodeScanner.checkPermission();
            if (cameraPer.denied) {
                openSettings()
                store.dispatch(setPermissions({type: "camera", status: false}));
            } else {
                const cameraPer = await BarcodeScanner.checkPermission({force: true});
                if (cameraPer.granted) {
                    store.dispatch(setPermissions({type: "camera", status: true}));
                    return
                }
                store.dispatch(setPermissions({type: "camera", status: false}));
            }
        } else {
            openSettings()
        }
    }
}

const handleLocationPermissions = {
    web: () => {
        try {
            navigator.geolocation.getCurrentPosition((data) => {
                if (data.coords.latitude !== undefined) {
                    store.dispatch(setPermissions({type: "location", status: true}))
                    return
                }
                store.dispatch(setPermissions({type: "location", status: false}))
            })
        } catch (error) {
            store.dispatch(setPermissions({type: "location", status: false}))
        }
    },
    native: async () => {
        const {location} = store.getState().device.permissions;
        if (!location) {
            const locationPermission = await Geolocation.requestPermissions(
                "location"
            );
            if (locationPermission.location === "granted") {
                store.dispatch(setPermissions({type: "location", status: true}));
            } else if (locationPermission.location === "denied") {
                store.dispatch(setPermissions({type: "location", status: false}));
                openSettings()
            }
        } else {
            store.dispatch(setPermissions({type: "location", status: false}));
            openSettings()
        }
    }
}

const handlePushNotificationPermissions = {
    native: async () => {
        const {notifications} = store.getState().device.permissions;
        if (!notifications) {
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === "granted") {
                    store.dispatch(setPermissions({type: "push_permission", status: true}));
                } else if (result.receive === "denied") {
                    store.dispatch(setPermissions({type: "push_permission", status: false}));
                    openSettings()
                }
            });
        } else {
            store.dispatch(setPermissions({type: "push_permission", status: false}));
            openSettings()
        }
    }
}
const handleNotificationPermissions = (slug) => {
    let permission = !store.getState().device.permissions[slug];
    editUser({[slug]: permission}).then((res) => {
        res.status && store.dispatch(setPermissions({type: slug, status: permission}));
    })
}

const handleNativePermissions = async (item) => {
    switch (item.slug) {
        case "camera":
            handleCameraPermission.native()
            break;
        case "location":
            handleLocationPermissions.native()
            break;
        case "push_permission":
            handlePushNotificationPermissions.native()
            break;
        default:
            break;
    }
}
const handleWebPermissions = async (item) => {

    switch (item.slug) {
        case "camera":
            handleCameraPermission.web()
            break;
        case "location":
            handleLocationPermissions.web()
            break;
        case "push_permission":
            Alert.error(t("notifications-is-not-supported-on-web"))
            break;
        case "sms_permission":
        case "email_permission":
            handleNotificationPermissions(item.slug)
            break;
        default:
            break;
    }
}
export const handlePermissions = async (item) => {
    const isNative = Capacitor.isNativePlatform()
    if (isNative) {
        handleNativePermissions(item)
    } else {
        handleWebPermissions(item)
    }
};
