import { general } from './MenuItems'
import {useSelector} from 'react-redux'
import {TGIcon, TGItem, TGList, TGText} from '../../TGElements'
import {t} from "i18next";
import React from "react";
import {useHistory} from "react-router-dom";
import {env} from "../../../helpers";
const MenuList = () => {
  const history = useHistory()
  const {language} = useSelector(state => state.user)
  const LeftIcon = ({...props}) => {
    return (
      <div className='TGItemIconWrapper'>
        <TGIcon {...props}/>
      </div>
    )
  }
  return (
    <div className="MenuList">
      <TGText
        fontSize={16}
        fontWeight={700}
        margin={"5px 0 12px 0"}
      >
        {t('general')}
      </TGText>
      <TGList className="Menu">
        {process.env.REACT_APP_IS_WALLET_ACTIVE === "true" ?
        <div className="TGItem TGItem-dark" onClick= { () => history.push('menu/wallet')}>
          <div>
            <TGIcon
                name={`wallet-higlight-${language.code}`}
                width={119}
                height={39.53}
            />
            <div>
              <div className='MenuHighlightsNewWrapper'>{t("new").toLowerCase()}</div>
            </div>
          </div>
          <TGIcon name={"right-arrow"} fill={"#F3F4F4"} cssClass={"ItemArrow"} />
        </div>
        : null}
        {env('IS_MEMBERSHIP_ACTIVE') === "true" ?
          <div className={"TGHalfItem TGItem-blue"} onClick={() => history.push('menu/membership/1')}>
            <TGIcon
              style={""}
              name={`menu-park`}
              width={39}
              height={38}
            />
            <p style={{color: "white", fontWeight: 500,marginLeft:10}}>{t("park-membership")}</p>
            <TGIcon name={"right-arrow"} fill={"white"} cssClass={"ItemArrow"}/>
          </div>
          : null}

        {env('IS_MEMBERSHIP_ACTIVE') === "true" && (
          <div
            style={{marginLeft:10}}
            className={"TGHalfItem TGItem-transparent"}
            onClick={() => window.open("https://toger.co/partnership", "_blank")}
          >
            <TGIcon
              style={""}
              name={`partnership`}
              width={34}
              height={35}
            />
            <p style={{color: "#191E26", fontWeight: 500,marginLeft:5}}
               dangerouslySetInnerHTML={{__html: t("partnership")}}
            ></p>
            <TGIcon name={"right-arrow"} fill={"#939598"} cssClass={"ItemArrow"}/>
          </div>
        )}


        {general.map((item) => (
          <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => history.push(`/menu/${item.slug}`)}
            leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width} height={item.leftIcon.height}/>}
          />
        ))}
      </TGList>
    </div>
  );
}

export default MenuList
