import React from 'react'
import {TGIcon, TGText} from '../../TGElements'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {setMenuModal} from '../../../redux/slices/modalSlice'
import {useHistory} from "react-router-dom";

const Vehicles = () => {
  const {t} = useTranslation()
  const history = useHistory()
  return (
    <div className='MenuHighlight Vehicles' onClick={() => history.push('/menu/vehicles')}>
      <div className='IconWrapper'>
        <TGIcon
          name={"vehicles-higlight"}
          width={19.5}
          height={13.7}
        />
      </div>
      <TGText
        fontSize={16}
        fontWeight={"semibold"}
        color={"var(--tg-white)"}
        margin={"8px 0 0 0"}
      >
        {t("vehicles")}
      </TGText>
      <div className='MenuHighlightsInfoIconWrapper'>
        <TGIcon
          name="info"
          size={17}
          cssClass={"MenuHighlightsInfoIcon"}
        />
      </div>
    </div>
  )
}

export default Vehicles