//React
import { useState, useEffect } from "react";
//React Router
import { useHistory } from "react-router-dom";
//Components
import {
  CompleteLater,
  CustomLogo,
  TGButton,
  TGDefaultPage,
  TGPage,
  TGText,
} from "../components";
//Helpers
import { registerPhone } from "../helpers";
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";

import {PhoneInput, CountrySelector, usePhoneInput} from "react-international-phone";
import "react-international-phone/style.css";
import SelectCountryModal from "../components/Country/SelectCountryModal";
import { Alert } from "../helpers/alert";
import { PushNotifications } from '@capacitor/push-notifications';
import { setPermissions } from "../redux/slices/deviceSlice";
import { Capacitor } from "@capacitor/core";
import { useCustomPhoneValidation } from "../hooks/useCustomPhoneValidation";

const RegisterPhone = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch()
  const { country } = useSelector((state) => state.user);
  const { keyboardHeight } = useSelector((state) => state.device);
  const { qrRedirection } = useSelector((state) => state.global);
  const [phone, setPhone] = useState("");
  const [countryModal, setCountryModal] = useState(false);
  const [TCPPModal, setTCPPModal] = useState({ status: false, type: "" });
  const phoneValidation = useCustomPhoneValidation(phone);
  const {country:countryPhoneCode} = usePhoneInput({
    defaultCountry: country.countryCode.toLowerCase(),
    value: phone,
  });


  const handleRegister = (userPhone) => {
    registerPhone(userPhone).then((res) => {
      res.success && history.push("/register-phone/confirm", { phone });
    });
  };

  useEffect(() => {
    requestPushNotificationPermission()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    setPhone(country.countryPhoneCode);
  }, [country]);
  
  const requestPushNotificationPermission = async () => {
    if(!Capacitor.isNativePlatform()) return;
    await PushNotifications.requestPermissions().then(result => {
        if(result.receive === "granted") {
          dispatch(setPermissions({type: 'notification', status: true}))
        }else{
          dispatch(setPermissions({type: 'notification', status: false}))
        }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRegister(phone);
  };

  const handleTCPPButton = (e) => {
    const button = e.target.id;

    if (button !== "") {
      setTCPPModal({ status: true, type: button });
    }
  };

  useEffect(() => {
    if (!!qrRedirection.pathname) {
      Alert.warning(t("login-first"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrRedirection.pathname]);

  const toggleCountryModal = () => setCountryModal((prev) => !prev);

  const CustomButton = (props) => <button onClick={toggleCountryModal} type="button" {...props} />

  const internationPhoneInputStyles = {
    countrySelectFlag: {
      width: "30px",
      height: "30px",
      background: "white"
    },
    phoneInputComponentStyle: {
      width: "100%",
      marginBottom: "20px",
    },
    phoneInputStyle: {
      width: "100%",
      height: "60px",
      borderTopRightRadius: "25px",
      borderBottomRightRadius: "25px",
      borderLeft: "none",
      fontSize: "18px",
      fontWeight: "600",
    },
    completeLater: {
      position: "absolute",
      bottom: keyboardHeight > 0 ? '20px': "120px",
      width: "calc(100% - 32px)",
    }
  }

  return (
    <>
      <TGPage scrollY={false} toolbar={false} cssClass="RegisterPhoneContent">
        <form className="register-form" onSubmit={handleSubmit}>
          <CustomLogo
            type="mono"
            width={202}
            style={{ margin: `110px auto` }}
          />

          <TGText
            fontWeight={"semibold"}
            fontSize={16}
            margin="0 0 9px 0"
            width={"80%"}
            styles={{ lineHeight: "19px" }}
            dangerouslySetInnerHTML={{ __html: t("enter-phone-to-register") }}
          />

          <div style={{ width: "100%", display: "flex" }}>

            <CountrySelector
              selectedCountry={countryPhoneCode}
              hideDropdown={true}
              flagStyle={internationPhoneInputStyles.countrySelectFlag}
              buttonContentWrapperClassName="country-selector-custom-button"
              renderButtonWrapper={CustomButton}
            />

            <PhoneInput
              value={phone}
              onChange={(e) => setPhone(e)}
              style={internationPhoneInputStyles.phoneInputComponentStyle}
              inputStyle={internationPhoneInputStyles.phoneInputStyle}
              hideDropdown={true}
            />

          </div>

          <SelectCountryModal isOpen={countryModal} setOpen={setCountryModal} />

          <TGButton type="submit" disabled={!phoneValidation}>
            <TGText
              color={
                phoneValidation
                  ? "var(--tg-white)"
                  : "var(--tg-secondary-text)"
              }
            >
              {phoneValidation ? t("next") : t("send-code")}
            </TGText>
          </TGButton>
        </form>

        {keyboardHeight === 0 && (
          <CompleteLater
              additionalStyles={internationPhoneInputStyles.completeLater}
              path={"/map"}
          />
        )}

        {keyboardHeight === 0 && (
          <TGText
            width={"calc(100% - 32px)"}
            fontSize={12}
            fontWeight="regular"
            textAlign="center"
            color={"var(--tg-item-gray2)"}
            cssClass="TermsAndConditionsText"
            dangerouslySetInnerHTML={{
              __html: t("terms-conditions-privacy-policy-button"),
            }}
            onClick={handleTCPPButton}
            textTransform="unset"
          />
        )}
      </TGPage>

      <TGDefaultPage
        slug={TCPPModal.type}
        isOpen={TCPPModal.status}
        type="modal"
        onBack={(status) => setTCPPModal({ status, type: "" })}
      />
    </>
  );
};

export default RegisterPhone;
