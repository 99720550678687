import React from 'react';
import {MenuButton} from "../MapButtons/Partials/MapActionButtons/Buttons";
import CampaignButton from "../MapButtons/Partials/MapActionButtons/Buttons/CampaignButton";
import {ScanButton} from "./components";
import {useSelector} from "react-redux";

const MapBottomButtons = () => {
  const {mapLoading} = useSelector(state => state.global)
  const { searchModal } = useSelector((state) => state.modal);

  return (
    <div className={`TGBottomToolbar ${!mapLoading.status && !searchModal.status && 'active'}`}>

      {/* menu button */}
      <MenuButton />

      {/* scan button */}
      <ScanButton />

      {/* campaign button */}
      <CampaignButton />

    </div>
  );
};

export default MapBottomButtons;