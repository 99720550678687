import { createSlice } from "@reduxjs/toolkit"

export const deviceSlice = createSlice({
  name: "deviceSlice",
  initialState: {
    isDeviceReady: false,
    permissions: {
        camera: false,
        location: false,
        push_permission: false,
        sms_permission: false,
        email_permission: false
    },
    flashlight: false,
    keyboardHeight: 0,
    scanner: { status: false, goWhenDisable: "" },
    fcmToken: ""
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions[action.payload.type] = action.payload.status
    },
    setFlashlight: (state, action) => {
      state.flashlight = action.payload
    },
    setDeviceReady: (state, action) => {
      state.isDeviceReady = action.payload
    },
    setKeyboardHeight: (state, action) => {
      state.keyboardHeight = action.payload
    },
    setScanner: (state, action) => {
      if(typeof action.payload === 'boolean') {
        state.scanner.status = action.payload
        return
      }
      state.scanner = action.payload
    },
    setFcmToken : (state, action) => {
      state.fcmToken = action.payload
    }
  },
})

export const {
    setPermissions,
    setFlashlight,
    setDeviceReady,
    setKeyboardHeight,
    setScanner,
    setFcmToken
} = deviceSlice.actions
export default deviceSlice.reducer
