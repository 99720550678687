import React from 'react'
import { TGIcon, TGItem, TGList, TGPage } from '../components'
import {t} from 'i18next'
import { useHistory } from 'react-router'
import { legal } from '../components/Menu/MenuContent/MenuItems'

const Agreements = () => {
  const history = useHistory()

  const LeftIcon = ({...props}) => {
    return (
      <div className='TGItemIconWrapper'>
        <TGIcon {...props}/>
      </div>
    )
  }
  return (
    <TGPage
      title={t("agreements")}
      scrollY={false}
      backButton={true}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end"}
    >
      <TGList>
        {legal.map((item) => (
          <TGItem
            key={item.id}
            slug={item.slug}
            handleClick={() => history.push(`/menu/about-grouped/agreements/${item.slug}`)}
            leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width} height={item.leftIcon.height}/>}
          />
        ))}
      </TGList>
    </TGPage>
  )
}

export default Agreements