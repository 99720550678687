import InvoiceItem from "./InvoiceItem"
import {Modals, NoEntries} from ".."
import InvoiceSkeleton from "./InvoiceSkeleton"

import {api, getInvoiceList} from "../../helpers"
import React, {useEffect, useState} from "react"
import {TGVirtualScroll} from "../TGElements"
import {t} from "i18next";
import {useDispatch} from "react-redux";
import {setCheckUserModal} from "../../redux/slices/modalSlice";
import {Alert} from "../../helpers/alert";

const InvoiceList = ({isLoading, lastPage}) => {
    const dispatch = useDispatch()
    const [invoices, setInvoices] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [unpaidExist, setUnpaidExist] = useState(null)

    const payAll = () => {
        api.checkUserAbleToStartSession(true, true, false).then((response) => {

            // payment method not found
            if (!response?.data?.response.paymentMethod) {
                dispatch(setCheckUserModal({debt: false, vehicle: false, paymentMethod: true}))
                return;
            }

            if (response?.data?.response.dept_description) {
                Alert.error(response.data.response.dept_description)
                return;
            }

            // success payment
            if (response?.data?.response.debt){
                setInvoices([]);
                getInvoices();
                setUnpaidExist(null);
            }

        })
    };

    const getDept = () => {
        api.getUserDept().then((res) => {
            if (res?.data?.response?.debt) {
                setUnpaidExist(res.data.response)
                return
            }
            setUnpaidExist(false)
        });
    }
    const getInvoices = () => {
        getInvoiceList(false, {}, currentPage)
            .then((result) => {
                setInvoices(prevInvoices => {
                    return [...prevInvoices, ...result.invoices];
                });
            }).then(() => getDept())
    };

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    if (isLoading) {
        return <InvoiceSkeleton/>
    }


    return (
        <div className="InvoiceList">
            {unpaidExist &&
                <>
                    <div className="PayAllTextWrapper">
                        <p>{unpaidExist.message}</p>
                    </div>
                    <button onClick={payAll} className="TGButton PayAllButton">
                        {t("pay-all-debt")}
                    </button>
                </>
            }
            {invoices?.length ? (
                <TGVirtualScroll
                    data={invoices}
                    item={(idx, invoice) => <InvoiceItem invoice={invoice} key={idx}/>}
                    handleEnd={() => currentPage < lastPage && setCurrentPage(prev => prev + 1)}
                />
            ) : (
                <NoEntries path={"invoices"}/>
            )}
            <Modals />
        </div>
    );
}

export default InvoiceList
