import {TGIcon} from "../TGElements";
import TGImg from "../TGElements/TGImg";
import {WalletNotice} from "../../assets/images";
import {t} from "i18next";
import React, {useEffect, useState} from "react";
import WalletListItem from "./walletListItem";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

const WalletPopup = () => {
    const [isOpen, setIsOpen] = useState(false)
    const {user} = useSelector(state => state.user);
    const history = useHistory();

    useEffect(() => {
        if (user.token
            && !localStorage.getItem("isWalletPopupSeen")
            && process.env.REACT_APP_IS_WALLET_ACTIVE === "true"){
            setIsOpen(true)
        }

        return () => {
            close()
        }
    }, [])
    const close = () => {
        setIsOpen(false)
        localStorage.setItem("isWalletPopupSeen", true)
    }
    const clickHandler = () => {
        close()
        history.push("menu/wallet")
    }

    return (
         isOpen ?
            <div className={"WalletPopupContainer"}>
                <div className={"WalletPopup"}>
                    <div style={{position: "absolute", right: 30}} onClick={() => close()}>
                        <TGIcon
                            name={"gray-close"}
                            width={30}
                            height={30}
                            margin={"0 5px 0 20px"}
                        />
                    </div>
                    <p style={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontWeight: "600",
                        marginTop: "40px"
                    }}>{t("wallet-release-message")}</p>
                    <p style={{fontWeight: "600"}} className={"PopupMessage"}>{t("get-campaigns-with-wallet")}</p>
                    <p style={{fontSize: "14px"}} className={"PopupMessage"}>{t("wallet-welcome-description")}</p>
                    <div>
                        <TGImg src={WalletNotice}/>
                    </div>
                    <div style={{fontSize: "14px", fontWeight: 450, marginTop: "20px"}}>
                        <WalletListItem text={t("wallet-package-detail-4")}/>
                        <WalletListItem text={t("wallet-package-detail-2")}/>
                        <WalletListItem text={t("wallet-package-detail-3")}/>
                    </div>

                    <button onClick={clickHandler} className={"GoToWallet"}>
                        {t("go-to-wallet")}
                    </button>


                </div>
            </div>
            : null
    );
};

export default WalletPopup;
