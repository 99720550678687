import { TGButton, TGIcon } from "../TGElements";
import {useHistory} from "react-router-dom";

const EditProfileButton = () => {
  const history = useHistory();
  return (
    <TGButton
      width={32}
      height={32}
      borderRadius={"32px"}
      backgroundActiveColor={"var(--tg-transparent)"}
      backgroundColor={"var(--tg-item-gray50)"}
      cssClass={"TGNotificationsButton"}
      onButtonClick={() => history.push("/menu/edit-user")}
      margin={"0 4px 0 0"}
    >
      <TGIcon name="edit" width={18} height={22} />
    </TGButton>
  );
};

export default EditProfileButton;
