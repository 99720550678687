import React from 'react';
import {TGPage, TGText} from "../components";
import {useTranslation} from "react-i18next";
import Subscriptions from "../components/Membership/Subscriptions";
import {useDispatch, useSelector} from "react-redux";
import {plateValidation} from "../helpers";
import {Alert} from "../helpers/alert";
import {setMembershipPlate} from "../redux/slices/membershipSlice";

const AddSubscription = () => {
    const {membershipPlate} = useSelector(state => state.membership)
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const changeHandler = (e) => {
        const plate = e.target.value.toUpperCase().trim();
        dispatch(setMembershipPlate(plate))
    }

    return (
        <TGPage
            title={t('memberships')}
            scrollY={false}
            backButton={true}
            cssClass={"AddSubscription"}
        >
            <TGText margin={"20px 0 10px 0"} fontWeight={600} fontSize={18}>{t("add-plate")}</TGText>
            <div className={"enterPlateWrapper"}>
                <input className={"vehicleInput"} maxLength={8} value={membershipPlate} onChange={changeHandler} type="text"/>
            </div>

            <Subscriptions/>

        </TGPage>
    );
};

export default AddSubscription;