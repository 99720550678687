//Components
import {TGBackButton, TGIcon, TGText} from "../../../TGElements";
import {useIonRouter} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setChangePaymentMethodModal} from "../../../../redux/slices/modalSlice";

const SessionHeader = () => {
  const {t} = useTranslation()
  const history = useIonRouter()
  const dispatch = useDispatch()

  const onBackHandler = () => {
    history.push("/map", 'back', 'pop')
  }

  const changePaymentModalHandler = () => {
    dispatch(setChangePaymentMethodModal(true))
  }

  return (
    <header className="SessionHeader">
      <TGBackButton type="secondary" onBack={onBackHandler}/>
      <div onClick={changePaymentModalHandler}>
        <TGIcon name="change-payment" size={24}/>
        <TGText fontSize={12} textAlign={"right"} fontWeight={500} color={"#000"}
                dangerouslySetInnerHTML={{__html: t("change-payment")}}/>
      </div>
    </header>
  );
};

export default SessionHeader;
