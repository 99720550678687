import {IonSelect, IonSelectOption} from "@ionic/react";
import {t} from "i18next";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPark} from "../../redux/slices/membershipSlice";

const ParkListDropdown = () => {
    const dispatch = useDispatch()
    const {selectedPark,allParks} = useSelector(state => state.membership)


    return (
        <IonSelect onIonChange={(e)=>dispatch(setSelectedPark(e.target.value))}
                   className={`ParkListDropdown ${selectedPark !== "" && "active"}`}
                   value={selectedPark}>
            <IonSelectOption value="">{t("choose-park-point")}</IonSelectOption>
            {allParks && allParks.map((park, key) => (
                <IonSelectOption key={key} value={park.id}>{park.name}</IonSelectOption>
            ))}
        </IonSelect>
    );
};

export default ParkListDropdown;
