import {IonItemOption, IonItemOptions, IonItemSliding, useIonViewDidEnter, useIonViewWillEnter} from "@ionic/react";
import {TGIcon, TGText} from "../../TGElements";
import {useTranslation} from "react-i18next";
import {removeVehicle} from "../../../helpers";
import Vehicle from "./Vehicle";
import {useDialog} from "../../../hooks";
import {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAddNewVehicleModal} from "../../../redux/slices/modalSlice";
import {setNewPlate} from "../../../redux/slices/newCarSlice";
import {setVehicleViewFirstVisit} from "../../../redux/slices/userSlice";
import {Alert} from "../../../helpers/alert";

const MyVehicle = ({ vehicle, isFirstItem }) => {
  const { t } = useTranslation();
  const vehicleItem = useRef()
  const {vehicleViewFirstVisit} = useSelector(state => state.user)
  const dispatch = useDispatch();
  const showDialog = useDialog();

  const closeSlidedButtons = () => {
    vehicleItem.current && vehicleItem.current.closeOpened()
  }

  useIonViewDidEnter(() => {
    if (vehicleItem.current && vehicleViewFirstVisit && isFirstItem) {
      vehicleItem.current.classList.add('firstVehicleAnimation', 'item-sliding-active-slide', 'item-sliding-active-options-end');
      dispatch(setVehicleViewFirstVisit(false));

      const timeout = setTimeout(() => {
        vehicleItem.current.classList.remove('firstVehicleAnimation', 'item-sliding-active-slide', 'item-sliding-active-options-end');
      }, 900);

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleDeleteVehicle = () => {
    const deleteVehicle = async () => {
      const success = await removeVehicle(vehicle.plate)
      success && closeSlidedButtons()
    }
    showDialog(t("remove-vehicle-confirm"), "yes", deleteVehicle, "no");
  }

  const handleEdit = () => {
    closeSlidedButtons()
    dispatch(setNewPlate(vehicle.plate));
    dispatch(setAddNewVehicleModal(true));
  }

  return (
    <IonItemSliding ref={vehicleItem} class="MyVehicleWrapper ion-color-danger">
      <Vehicle vehicle={vehicle} />
      <IonItemOptions side="end">
        <IonItemOption color={"danger"} onClick={handleDeleteVehicle}>
          <div className={"OptionsItem"}>
            <TGIcon name={"remove-vehicle"} size={21} />
            <TGText
              textAlign={"center"}
              color={"var(--tg-white)"}
              fontWeight={"medium"}
              fontSize={"12px"}
              dangerouslySetInnerHTML={{ __html: t("delete") }}
            />
          </div>
        </IonItemOption>
          <IonItemOption onClick={handleEdit}>
            <div className={"OptionsItem"}>
              <TGIcon name={"edit-vehicle"} size={21} />
              <TGText
                  textAlign={"center"}
                  color={"var(--tg-white)"}
                  fontWeight={"medium"}
                  fontSize={"12px"}
                  dangerouslySetInnerHTML={{ __html: t("edit") }}
              />
            </div>
          </IonItemOption>
      </IonItemOptions>
      <div className={`MyVehicleWrapperBorder ${vehicle.is_default && 'Active'}`}></div>
    </IonItemSliding>
  );
};

export default MyVehicle;
