import {TGButton, TGIcon, TGText} from '../../../../TGElements';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

const CampaignButton = () => {
    const {t} = useTranslation()
    const history = useHistory()

    return (
        <div className="TGMapButtonWrapper">
            <TGButton
              width={48}
              height={48}
              borderRadius={"18px"}
              backgroundColor={"var(--tg-white)"}
              expand="none"
              cssClass="TGMapButton"
              onButtonClick={() => history.push("/campaign")}
              backgroundActiveColor={"var(--tg-item-gray)"}
              additionalStyle={{
                  color:'var(--tg-secondary)',
              }}
            >
                <TGIcon name="gift-box" width={20.64} height={21.81} />
            </TGButton>
            <TGText fontSize={14} fontWeight={500}>
              {t('gift-text')}
            </TGText>
        </div>
    );
}

export default CampaignButton;
