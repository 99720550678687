//Components
import { TGIcon, TGImg, TGText } from "../../../TGElements";
import SessionCar from "../../../../assets/images/session-car.png";
//Helpers
import { useTranslation } from "react-i18next";
import {LottiePlayer} from "../../../index";
import {useSelector} from "react-redux";

const ChargingVehicle = () => {
  const { t } = useTranslation();
  const activeSession = useSelector((state) => state.session.activeSession);
  return (
    <div className={"ChargeVehicleWrapper"}>
      <TGImg src={SessionCar} alt="SESSION_CAR" cssClass={"SessionCar"}/>
      <div className="ChargingText">
        <TGIcon name="charging" size={48}/>

        {activeSession?.battery_percent ? (
          <TGText fontWeight={"bold"} fontSize={30} color={"var(--tg-white)"}>
            {activeSession.battery_percent}%
          </TGText>
        ) : undefined}

        <TGText fontWeight={"medium"} fontSize={11} color={"var(--tg-white)"}>
          {t("charging")}
        </TGText>
      </div>
      <LottiePlayer
        cssClass={"ActiveChargeAnimation"}
        animationName={"active-charge-animation"}
        loop={true}
      />
    </div>
  );
};

export default ChargingVehicle;
