import { TGIcon, TGText } from '../../TGElements'
import { useTranslation } from 'react-i18next'
import {useHistory} from "react-router-dom";

const Payments = () => {
  const {t} = useTranslation()
  const history = useHistory()

  return (
    <div
      className='MenuHighlight Payments'
      onClick={() => history.push('/menu/payments')}
    >
      <div className='IconWrapper'>
        <TGIcon
        name={"payments-higlight"}
        width={19.5}
        height={13.7}
        />
      </div>
      <TGText
      fontSize={16}
      fontWeight={"semibold"}
      color={"var(--tg-white)"}
      margin={"8px 0 0 0"}
      >
        {t("payments")}
      </TGText>
      <div className='MenuHighlightsInfoIconWrapper'>
        <TGIcon
          name="info"
          size={17}
          cssClass={"MenuHighlightsInfoIcon"}
        />
      </div>
    </div>
  )
}

export default Payments