import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import {TGButton, TGInput, TGText} from "../TGElements";
import {useSelector} from "react-redux";
import {useIonRouter, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {getStationDetailByCode, isUserAbleToStartSession, startCharge} from "../../helpers";
import {Alert} from "../../helpers/alert";
import {useHistory} from "react-router-dom";

const StartSessionForm = () => {
    const {t} = useTranslation()
    const [code, setCode] = useState("");
    const {keyboardHeight} = useSelector(state => state.device)
    const inputRef = useRef(null);
    const history = useHistory();
    const router = useIonRouter()

    useIonViewDidEnter(() => {
        inputRef.current?.setFocus();
    }, []);

    useIonViewWillLeave(() => {
        setCode("");
    });

    const handleCodeChange = (e) => {
        const value = e.target.value;
        // only allow numbers
        if (isNaN(value)) return;
        setCode(value)
    }


    const submitHandler = async (code) => {

        const isExistConnection = await getStationDetailByCode(code)
        if (!isExistConnection) {
            setCode("")
            Alert.error(t("connection-not-found"))
            return
        }

        const {start, isCableConnected} = await startCharge(code)
        start && router.push("/active-session", 'forward', 'pop', {isCableConnected: isCableConnected})
    }

    return (
        <div className={"StartSessionForm"}>
            <TGInput
                reference={inputRef}
                type={"tel"}
                placeholder={"0000"}
                value={code}
                onInputChange={handleCodeChange}
                textAlign={"center"}
                maxlength={4}
            />

            <div className={`ConfirmButtonWrapper keyboard-${!!keyboardHeight}`}>
                <TGButton
                    disabled={!!code === false}
                    onButtonClick={() => submitHandler(code)}
                >
                    <TGText color={"#fff"}>{t("confirm")}</TGText>
                </TGButton>
            </div>
        </div>
    );
}

export default StartSessionForm;
