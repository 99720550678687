//React
import {useState} from "react";
//Components
import {
    TGPage,
    HiddenCar,
    TGInput,
    TGButton,
    TGImg,
} from "../components";
//Redux
import {useDispatch, useSelector} from "react-redux";
import {setNewPlate} from "../redux/slices/newCarSlice";
//Helpers
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {plateValidation, saveMyCar} from "../helpers";
import {Alert} from "../helpers/alert";
import {useIonRouter, useIonViewDidEnter, useIonViewWillEnter} from "@ionic/react";

const EnterPlate = () => {
    const router = useIonRouter();
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [vehicleToEdit, setVehicleToEdit] = useState({});
    const {newPlate} = useSelector((state) => state.newCar);
    const error = router.routeInfo.routeOptions?.error

    const clickHandler = async () => {
        const isValidPlate = plateValidation(newPlate);
        if (!isValidPlate) return Alert.error(t("valid-plate"));
        const vehicle = {plate: newPlate}
        const success = await saveMyCar(vehicle);
        if (success) {
            history.goBack()
        }
    }

    useIonViewWillEnter(() => {
        dispatch(setNewPlate(""));
    }, []);

    useIonViewDidEnter(() => {
        if (error) {
            Alert.error(t("vehicle-already-exists"));
        }
    }, []);


    return (
        <TGPage title={t("enter-plate")} scrollY={false} backButton={true}>
            <div className="EnterPlateContent">
                {vehicleToEdit.id ? (
                    <TGImg
                        src={vehicleToEdit.image}
                        width={200}
                        additionalStyles={{
                            transform: "translateY(50%)",
                            margin: "0 auto",
                        }}
                    />
                ) : (
                    <HiddenCar/>
                )}
                <TGInput
                    label={t("enter-plate")}
                    additionalStyles={{
                        transform: "translateY(165px)",
                    }}
                    maxlength={8}
                    onInputChange={(e) => dispatch(setNewPlate(e.detail.value))}
                    placeholder={newPlate}
                />
                <TGButton
                    disabled={!(newPlate.length > 4)}
                    additionalStyle={{
                        transform: "translateY(200px)",
                        width: "100%",
                    }}
                    onButtonClick={clickHandler}
                >
                    {t("next")}
                </TGButton>
            </div>
        </TGPage>
    );
};

export default EnterPlate;
