//Ionic & React
import { setupIonicReact } from '@ionic/react';
import { useEffect } from 'react';
//Redux
import { setKeyboardHeight } from './redux/slices/deviceSlice';
import { setConfig } from './redux/slices/configSlice';
import { useDispatch, useSelector } from 'react-redux';
// Theme variables
import './theme/theme.css';
//Components
import TGApp from './TGApp';
import { Scanner } from './components/Scanner';
//Helper
import i18next from "i18next";
import {Alert} from "./helpers/alert";
import { api, handleStatusbar, initializeSentry } from './helpers';
//Capacitor
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
setupIonicReact();

const App = () => {
  const dispatch = useDispatch()
  const {language} = useSelector(state => state.user)
  const {scanner} = useSelector(state => state.device)
  const isNative = Capacitor.isNativePlatform()

  const checkLanguage = async () => {
    await i18next.changeLanguage(language.code)
  }

  const getConfigData = () => {
    const isLoading = false
      api.getConfig(isLoading).then((result) => {
        if(result?.status === 200 && result.data?.status === true) {
            dispatch(setConfig(result.data?.config))
        }
      })
  }

  useEffect(() => {
    getConfigData()
    initializeSentry()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
      SplashScreen.hide()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(language.code) {
      checkLanguage().catch(() => window.location.reload())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  useEffect(() => {
    isNative && handleStatusbar(scanner.status)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanner.status])

  useEffect(() => {

    if(isNative){
      Keyboard.addListener('keyboardWillShow', info => dispatch(setKeyboardHeight(info.keyboardHeight)));
      Keyboard.addListener('keyboardWillHide', () => dispatch(setKeyboardHeight(0)));

      return () => {
        Keyboard.removeAllListeners().catch(() => Alert.warning("Error removing keyboard listeners"));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TGApp/>
};

export default App;
