//Components
import LottiePlayer from "../LottiePlayer/LottiePlayer";
import {TGButton, TGText} from "../TGElements";
//Redux
import {setChangeCreditCardModal, setConfirmMailModal} from "../../redux/slices/modalSlice";
import {useDispatch, useSelector} from "react-redux";
//Helpers
import {api, getUser, resendVerificationMail} from "../../helpers";
import {useTranslation} from 'react-i18next';
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import TGIcon from "../TGElements/TGIcon";
import React from "react";

const ConfirmMailModal = ({onButtonClick}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {user} = useSelector(state => state.user)
  const {confirmMailModal} = useSelector(state => state.modal)


  const handleResendMail = async () => {
    const {success} = await resendVerificationMail()
    if (success) {
      dispatch(setConfirmMailModal(false))
    }
  }

  return (
    <IonModal
      isOpen={confirmMailModal?.status}
      onDidDismiss={() => dispatch(setConfirmMailModal(false))}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top">
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={() => dispatch(setConfirmMailModal(false))}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end ConfirmMailModal'}>
        <LottiePlayer
          width={188}
          height={116}
          animationName="mail-animation"
        />
        <TGText
          color="var(--tg-primary-text)"
          fontSize={20}
          fontWeight="semibold"
          textAlign="center"
          width="100%"
          margin={"15px 0 17px 0"}
        >
          {t("confirm-your-mail")}
        </TGText>
        <TGText
          color={"var(--tg-secondary-text)"}
          fontSize={16}
          margin={"0 0 25px 0"}
          fontWeight="regular"
          textAlign={"center"}
          width="100%"
          cssClass={"confirm-mail-text"}
          textTransform={"none"}
          dangerouslySetInnerHTML={{__html: t("confirm-mail-text", {mail: user.info.email})}}
        />

        <TGButton
          onButtonClick={() => {
            onButtonClick && onButtonClick()
            getUser()
            dispatch(setConfirmMailModal(false))
          }}
          backgroundColor={"var(--tg-dark)"}
          backgroundActiveColor={"var(--tg-black-active)"}
        >
          <TGText
            color={"var(--tg-white)"}
            fontSize={18}
            fontWeight="semibold"
          >
            {t("done")}
          </TGText>
        </TGButton>

        <TGText
          onClick={handleResendMail}
          margin={"20px auto 50px auto"}
          color={"var(--tg-primary-text)"}
          textTransform={"none"}
          fontSize={16}
          fontWeight={"medium"}
        >
          {t("resend")}
        </TGText>


      </div>
    </IonModal>
  );
}

export default ConfirmMailModal;
