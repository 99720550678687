import React from "react";

const SessionVehicleClippingMask = () => {
  return (
    <svg id="outline-car">
      <defs>
        <clipPath id="clipPath">
          <path
            id="Path_226666"
            data-name="Path 226666"
            d="M75.637.258c23.985-.276,36.038-.406,39,0,11.133,1.525,24.928,3.568,39,13a57.207,57.207,0,0,1,16,15c7.335,10.4,9.185,21.018,10,26a78.329,78.329,0,0,1,1,14s.517,10.41-1,15c-.006.018-.337,1.012-1,3-.932,2.8-.981,2.944-1,3-1.326,4.022-1,10-1,10,.418,7.655-.521,15.351,0,23v63c0,26.333,3.042,52.843,0,79-.232,1.994-1.441,11.29,0,17a33.593,33.593,0,0,1,1,11c-.02.292-.014,5.528,0,16,.021,16.387.891,24.666,0,38a61.545,61.545,0,0,1-1,8c-.434,2.233-1.242,5.7-5,15-3.578,8.853-5.439,13.394-9,19a50.9,50.9,0,0,1-5,7c-6.342,7.051-15.065,9.156-25,11-16.671,3.094-27.53,5.109-44,5-.855-.006-11.581-1-33-3a86.607,86.607,0,0,1-22-5,31.818,31.818,0,0,1-9-5,32.743,32.743,0,0,1-7-8c-3.126-4.791-3.238-6.768-7-16-4.289-10.525-6.642-14.085-8-21-.542-2.758-1-6-1-6-.558-3.946-.821-7.181-1-10-.743-11.675-.949-30.309,0-42,.384-4.733,1-9,1-9,.452-3.13.885-5.382,1-6,1.6-8.621,1-22,1-22-.181-4.036-2.3-54.485,0-95,1.169-20.636.614-41.334,1-62a49.063,49.063,0,0,0-1-11c-.433-2.107-.888-3.614-1-4-.351-1.21-7.29-28.133,3-50h0a55.385,55.385,0,0,1,17-23,56.6,56.6,0,0,1,17-9c10.1-3.594,17.752-3.79,36-4"
            fill="transparent"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SessionVehicleClippingMask;
