import { t } from "i18next";
import { TGPage, TicketList } from "../components";

const Tickets = () => {
  return (
    <TGPage
    title={t("contact-us")}
    scrollY={true}
    backButton={true}
    ionPadding={false}
    cssClass={"ion-padding-start ion-padding-end"}
    >
      <TicketList />
    </TGPage>
  );
};

export default Tickets;
