import {TGItem, TGList, TGPage, TGToggle} from '../components'
import {Permissions} from '../assets/Permissions'
import {useEffect} from 'react'
import {getPermissions, getUser, handlePermissions} from '../helpers'
import {App} from '@capacitor/app'
import {Alert} from '../helpers/alert'
import {useSelector} from 'react-redux'

const ManagePermissions = () => {
    const {permissions} = useSelector(state => state.device)

    const checkPermissions = () => {
        getPermissions('location')
        getPermissions('camera')
        getPermissions('sms_permission')
        getPermissions('push_permission')
        getPermissions('email_permission')
    }

    useEffect(() => {
        checkPermissions()
        getUser()
        App.addListener('appStateChange', ({isActive}) => isActive && checkPermissions());
        return () => App.removeAllListeners().catch(err => Alert.error(err));
    }, [])

    return (
        <TGPage
            scrollY={false}
            backButton={true}
            ionPadding={false}
            cssClass={"ion-padding-start ion-padding-end"}
        >
            <TGList title="manage-permissions">
                {Permissions.map((item) => {
                    let isChecked;

                    switch (item.slug) {
                        case "camera":
                            isChecked = permissions.camera;
                            break;
                        case "push_permission":
                            isChecked = permissions.push_permission;
                            break;
                        case "email_permission":
                            isChecked = permissions.email_permission;
                            break;
                        case "sms_permission":
                            isChecked = permissions.sms_permission;
                            break;
                        default:
                            isChecked = permissions.location;
                    }

                    return (
                        <TGItem
                            key={item.id}
                            slug={item.slug}
                            handleClick={() => handlePermissions(item)}
                            rightIcon={<TGToggle checked={isChecked}/>}
                            leftIcon={false}
                        />
                    );
                })}
            </TGList>
        </TGPage>
    );
}

export default ManagePermissions