import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import {getStationDetailByCode, getUser, isUserAbleToStartSession, startCharge, startScan} from "./helpers";
import {Alert} from "./helpers/alert";
import {useTranslation} from "react-i18next";
import {useIonRouter} from "@ionic/react";
import {store} from "./redux/store";
import {setSuccessMailVerificationModal} from "./redux/slices/modalSlice";

const AppUrlListener = () => {
  const {t} = useTranslation();
  let history = useHistory();
  const router = useIonRouter()
  useEffect(() => {
    App.addListener('appUrlOpen', async (event) => {

      const code = event.url.split('/').pop();

      if (code === 'email_verification') {
        return getUser().then(() => {
          store.dispatch(setSuccessMailVerificationModal(true))
          history.push('/map')
        })
      }


      if (!code) return;

      const isAble = await isUserAbleToStartSession()
      if(!isAble) return;

      const isExistConnection = await getStationDetailByCode(code)
      if (!isExistConnection) {
        Alert.error(t("connection-not-found"))
        return
      }

      const {start, isCableConnected} = await startCharge(code)
      start && router.push("/active-session", 'forward', 'pop', {isCableConnected: isCableConnected})
    });

  }, []);

  return null;
};

export default AppUrlListener;