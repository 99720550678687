import React from 'react';
import WarningIcon from "../MailVerificationToast/warning_icon.svg";
import CloseIcon from "../MailVerificationToast/close_icon.svg";
import {IonToast} from "@ionic/react";
import "./DevelopmentWarning.css";

const DevelopmentWarning = ({active}) => {
  return (
    <IonToast
      isOpen={active}
      position="top"
      header="Development Mode"
      icon={WarningIcon}
      swipeGesture={"vertical"}
      className={"dev-mode-toast"}
      style={{'--start': '16px', '--end': '16px'}}
      buttons={[
        {
          icon: CloseIcon,
          htmlAttributes: {
            'aria-label': 'close',
          }
        }
      ]}
    ></IonToast>
  );
};

export default DevelopmentWarning;