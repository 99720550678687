import {useSelector} from 'react-redux';
import {LocateButton, SearchButton} from "./Buttons";

const MapActionButtons = ({map}) => {
    const {mapLoading} = useSelector(state => state.global)
    const {searchModal} = useSelector(state => state.modal)

    return (
      <div className={`MapActionButtonsBottom ${!mapLoading.status && !searchModal.status ? "active" : ""}`}>
        <SearchButton />
        <LocateButton map={map}/>
      </div>
    );
}

export default MapActionButtons;
