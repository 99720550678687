import { TGPage } from "../components"
import {t} from 'i18next'
import TGAccordion from "../components/TGElements/TGAccordion"
import { useEffect, useState } from "react"
import { api } from "../helpers"

const FAQPage = () => {
  const [faq, setFaq] = useState(null)

  useEffect(() => {
    api.getFAQ().then(result => {
      if(result.status === 200 && result.data){
        setFaq(result.data)
      }
    })
  }, [])


  return (
    <TGPage
      title={t("faq")}
      scrollY={true}
      backButton={true}
      ionPadding={false}
      cssClass={"ion-padding-start ion-padding-end"}
    >
      <TGAccordion.Group>
        {faq?.map((item, key) => {
          return (
            <TGAccordion key={item.title} value={key} title={item.title}>
              {item.content}
            </TGAccordion>
          )
        })}
      </TGAccordion.Group>
    </TGPage>
  )
}

export default FAQPage