import { ContactForm, TGPage, TGText } from '../components'
import {t} from 'i18next'

const ContactUs = () => {

  return (
    <TGPage
      title={t("contact-us")}
      scrollY={false}
      backButton={true}
    >
      <TGText fontWeight={500} margin={"1rem 0"}>
        {t("contact-form")}
      </TGText>
      <ContactForm />
    </TGPage>
  );
}

export default ContactUs