import { TGButton, TGText } from "../TGElements";
import {t} from 'i18next'
import {useIonRouter} from "@ionic/react";
const ActionButton = () => {
  const history = useIonRouter();

  const onButtonClickHandler = () => {
    //this push action clears the history stack
    history.push("/map",'forward','pop')
  }

  return (
    <TGButton
      backgroundColor={"var(--tg-primary-text)"}
      backgroundActiveColor={"var(--tg-black-active)"}
      additionalStyle={{
        position: "absolute",
        bottom: 30,
        width: "calc(100% - 32px)",
      }}
      onButtonClick={onButtonClickHandler}
    >
      <TGText color={"var(--tg-white)"}>
      {t("done")}
      </TGText>
    </TGButton>
  );
};

export default ActionButton;
