import {useEffect} from 'react'
import {MyVehicles, TGPage} from '../components'
import {useTranslation} from 'react-i18next'
import {getVehicles} from '../helpers'
import {useIonRouter} from "@ionic/react";

const Vehicles = () => {
  const {t} = useTranslation()
  const history = useIonRouter()
  useEffect(() => {
    getVehicles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TGPage
      title={t("vehicles")}
      scrollY={false}
      backButton={true}
      onBack={()=>history.push('/menu','back','pop')}
    >
      <MyVehicles/>
    </TGPage>
  )
}

export default Vehicles