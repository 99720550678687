//Ionic & React
import { useEffect, useState } from "react";
//Redux
import { useSelector } from "react-redux";
//Helpers
import { getUser } from "../../helpers";
//Components
import TGText from "./TGText";
import TGImg from "./TGImg";
import TGSkeleton from "./TGSkeleton";
import TGIcon from "./TGIcon";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";

const TGUser = ({ margin }) => {
  const { t } = useTranslation();
  const history = useHistory()
  const [isSkeleton, setSkeleton] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { first_name, last_name, profile_photo_url, email } = user?.info;
  const isUserInfoExist = first_name || last_name || email;

  useEffect(() => {
    if (isUserInfoExist) {
      setSkeleton(false)
      return;
    }
    if (user.token) {
      getUser(false).finally(() => setSkeleton(false));
    }
    if (!user.token) {
      setSkeleton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Avatar = () => <TGImg type={"avatar"} size={60} src={profile_photo_url} additionalStyles={{objectFit: 'cover'}} />

  const Skeleton = () => {
    return (
      <div
        className="TGUser"
        style={{
          margin: margin || 0,
        }}
      >
        <TGSkeleton width={60} height={60} borderRadius={60} type="secondary" />
        <div>
          <TGSkeleton width={120} type="secondary" />
          <TGSkeleton width={190} type="secondary" />
        </div>
      </div>
    );
  };

  const CompleteUser = () => {
    return (
      <>
        <div className="UserIconWrapper">
          {profile_photo_url ? <Avatar /> : <TGIcon name="user" width={25} height={32} />}
        </div>
        <TGText
          color="var(--tg-white)"
          fontWeight={500}
          fontSize={18}
          textTransform={"unset"}
          dangerouslySetInnerHTML={{ __html: t("complete-user-info") }}
        />
      </>
    );
  };

  const User = () => {
    return (
      <>
        <Avatar />
        <div>
          <TGText color={"var(--tg-white)"} fontSize={20} fontWeight={"semibold"}>
            {first_name} {last_name}
          </TGText>
          <TGText
            color={"var(--tg-secondary-text)"}
            fontSize={16}
            fontWeight={"regular"}
            textTransform={"none"}
          >
            {email}
          </TGText>
        </div>
      </>
    );
  };

  if (isSkeleton) {
    return <Skeleton />;
  }
  return (
    <div
      className="TGUser"
      style={{margin: margin || 0}}
      onClick={() => !isUserInfoExist && history.push("/menu/edit-user")}
    >
      {isUserInfoExist ? <User /> : <CompleteUser />}
    </div>
  );
};

export default TGUser;
