import React, {useEffect} from 'react';
import {TGButton, TGIcon, TGText} from "../components";
import {env, getWalletBalance} from "../helpers";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  IonButtons,
  IonHeader,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import {
  SelectInvoiceTypeModal,
  PaymentTypeModal,
  CardWrapper,
  InvoiceTypeWrapper
} from "../components/ChangePaymentMethodModal";
import {useLocation} from "react-router";
import { setChangePaymentMethodModal } from "../redux/slices/modalSlice";

const ChangePaymentMethod = () => {
    const {status:isActive} = useSelector(state => state.modal.changePaymentMethodModal)
    const {activeSession} = useSelector(state => state.session)
    const {t} = useTranslation();
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        getWalletBalance()
    }, [location])

    const closeModalHandler = () => {
      dispatch(setChangePaymentMethodModal(false))
    }


    return (
      <>
        <IonModal
          className={"TGModal"}
          isOpen={isActive}
          breakpoints={[0,1]}
          initialBreakpoint={1}
          onIonModalDidDismiss={closeModalHandler}
          style={{'--height': 'auto'}}
        >

          <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
            <IonToolbar className="TGHeaderToolbar ion-padding-top ion-padding-bottom ion-no-padding">
              <div className={`ModalTopLabelsWrapper active`}>
                <div>
                  <TGIcon name="CHaMoade-plug" size={22} margin={"0 5px 0 0"}/>
                  <TGText fontSize={12} textAlign={"right"} fontWeight={600}>
                    {activeSession?.connection}
                  </TGText>
                  <div className={"divider"}/>
                  <TGText fontSize={12} color={"var(--tg-gray-2)"}>
                    {t('connector-type', {type: activeSession?.connector_type})}
                  </TGText>
                </div>
                <div>
                  <TGText fontSize={14} textAlign={"right"} fontWeight={600} color={"#000"}>
                    {activeSession.unit_price}{env('CURRENCY_SYMBOL')}
                  </TGText>
                  <span className={"circle-icon"}></span>
                  <TGText fontSize={11} textAlign={"right"} fontWeight={500} color={"#000"}>
                    {t('active-text')}
                  </TGText>
                </div>
              </div>
              <IonButtons slot="end">
                <TGIcon name={"close-modal"} size={30} onClick={closeModalHandler}/>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

            <div className={"TGPageContent ion-padding-start ion-padding-end"}>
              <TGText margin={"0px 0 6px 0"} fontWeight={600} textTransform={"none"}>
                {t('SelectPaymentMethod.payment-type')}
              </TGText>

              <CardWrapper type={activeSession?.payment_type_id?? 1}/>

              <TGText margin={"24px 0 16px 0"} fontWeight={600} textTransform={"none"}>
                {t('invoice-address')}
              </TGText>

              <InvoiceTypeWrapper companyId={activeSession?.company_id}/>

              <TGButton
                onButtonClick={closeModalHandler}
                backgroundColor={"var(--tg-primary-text)"}
                backgroundActiveColor={"var(--tg-black-active)"}
                additionalStyle={{
                  maxHeight: "50px",
                  fontSize: "16px",
                  width: "100%",
                  textTransform: "none",
                  margin: "30px 0 20px",
                }}
              >
                {t('close')}
              </TGButton>
            </div>
        </IonModal>

        <PaymentTypeModal/>
        <SelectInvoiceTypeModal/>
      </>
    );
};

export default ChangePaymentMethod;