import React from 'react';
import {TGIcon, TGText} from "../../TGElements";
import TGImg from "../../TGElements/TGImg";
import {MasterCard, Visa} from "../../../assets/images";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setChangeCreditCardModal} from "../../../redux/slices/modalSlice";
/*
* CardWrapper Component
* type: 1 | 2 (1: Credit-Card, 2: Wallet)
 */
const CardWrapper = ({type = 1 | 2 }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {paymentMethods, balance} = useSelector(state => state.user)
  const defaultCard = paymentMethods?.find(card => card.default === 1);

  const openChangePaymentTypeModal = () => {
    dispatch(setChangeCreditCardModal(true))
  }

  return (
    <div className={`CardWrapper`}>
      {type === 2 && (
        <div className={"CardDetail"}>
          <TGIcon name={"small-wallet"} width={37} height={32}/>
          <div>
            <TGText fontSize={14} fontWeight={500} margin={"0 0 2px 0"}>Enerji Cüzdanı</TGText>
            <TGText fontSize={20} fontWeight={500} textTransform={"none"}><b>{balance ?? '0'}</b> kw</TGText>
          </div>
        </div>
      )}
      {type === 1 && (
        <div className={"CardDetail"}>
          <div className={"PaymentMethodsContainerStart"}>
            <TGImg width={41} src={defaultCard?.card?.brand === "MASTER_CARD" ? MasterCard : Visa}/>
          </div>
          <div className={"PaymentMethodsContainerMiddle"}>
            <TGText fontSize={16} fontWeight={500}>**** **** **** {defaultCard?.card?.last4}</TGText>
          </div>
        </div>
      )}
      <button className={"ChangeCardButton"} onClick={openChangePaymentTypeModal}>
        {t("change")}
      </button>
    </div>
  );
};

export default CardWrapper;