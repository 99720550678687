import uniqid from "uniqid";
export const general = [
  // { id: uniqid(), slug: "statistics" }, //TODO: will add when feature is ready
  { id: uniqid(), slug: "pricing", leftIcon: {width: 19.5, height: 19.5} },
  { id: uniqid(), slug: "about-grouped", leftIcon: {width: 19.5, height: 19.5} },
  { id: uniqid(), slug: "support", leftIcon: {width: 19.2, height: 19.2} },
  { id: uniqid(), slug: "settings", leftIcon: {width: 19.5, height: 19.5} },
];
export const legal = [
  { id: uniqid(), slug: "license", leftIcon: {width: 15.7, height: 21} },
  { id: uniqid(), slug: "terms-and-conditions", leftIcon: {width: 18, height: 20} },
  { id: uniqid(), slug: "privacy-and-policy", leftIcon: {width: 18, height: 21.8} },
];
export const settings = [
  { id: uniqid(), slug: "change-language" },
  { id: uniqid(), slug: "manage-permissions" },
];