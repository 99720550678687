import {TGIcon, TGImg, TGText} from "../TGElements";
import {NoVehicleSearch} from "../../assets/images";
import {t} from "i18next";
import React, {useRef, useState} from "react";
import MembershipVehicle from "./MembershipVehicle";
import {
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
} from "@ionic/react";
import uniqid from "uniqid";
import DeleteMembershipModal from "./DeleteMembershipModal";
import {api} from "../../helpers";
import {useSelector} from "react-redux";

const MembershipVehicles = ({getMembershipDetail = null}) => {
    const {userVehicles,selectedPark} = useSelector(state => state.membership)
    const subItem = useRef()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [removedVehicleId, setRemovedVehicleId] = useState(null)
    const [modalType, setModalType] = useState("free")
    const [availableUntil, setAvailableUntil] = useState(null)
    const openDeleteModal = (id,type,date) => {
        setRemovedVehicleId(id)
        setIsDeleteModalOpen(true);
        setModalType(type)
        setAvailableUntil(date)
    }

    const handleRemoveMembershipVehicle = () => {
        api.deleteMembershipVehicle(selectedPark, removedVehicleId).then((r) => {
            setIsDeleteModalOpen(false)
            getMembershipDetail(selectedPark, removedVehicleId);
        })
    }

    const menuList = [
        {
            id: uniqid(),
            text: "delete",
            iconDelete: "remove-vehicle",
            iconCancel: "cancel-subscription",
            onClick: openDeleteModal
        },
    ];

    return (
        <div className={"MembershipVehicles"}>
            {userVehicles && userVehicles.length ?
                userVehicles.map((vehicle) => (
                    <IonItemSliding key={"slide"+vehicle.id} ref={subItem} className={"SubscriptionSlideItem"}>
                        <MembershipVehicle style={{backgroundColor: "white"}} vehicle={vehicle}/>
                        {!vehicle.isPassive && (
                            <IonItemOptions className={"MembershipVehicleOptions"} side="end">
                                {menuList.map((item, index) =>
                                    <IonItemOption
                                        className={"MembershipVehicleOption"}
                                        onClick={() => item.onClick(vehicle.uid,vehicle.type,vehicle.availableUntil)}
                                        key={vehicle.id}
                                        disabled={item.disabled}
                                    >
                                        <div className={"actionButtonSlide"}>

                                            {vehicle.type === "free" ?
                                                <TGIcon name={item.iconDelete} size={21}/>
                                                : <TGIcon name={item.iconCancel} size={21}/>
                                            }

                                            <TGText
                                                textAlign={"center"}
                                                color={"var(--tg-white)"}
                                                fontWeight={600}
                                                textTransform={"none"}
                                                fontSize={12}
                                            >
                                                {t(vehicle.type === "free" ? "delete" : "cancel")}
                                            </TGText>
                                        </div>
                                    </IonItemOption>
                                )}
                                <div className={"vehicleItemBackground"}/>
                            </IonItemOptions>
                        )}
                    </IonItemSliding>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg src={NoVehicleSearch}/>
                    <p>{t("no-vehicles-added")}</p>
                    <p>{t("parking-benefit")}</p>
                </div>
            }

            <DeleteMembershipModal date={availableUntil} type={modalType} isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} deleteHandler={handleRemoveMembershipVehicle}/>
        </div>
    );
};


export default MembershipVehicles;
