import React from 'react';
import {setSuccessMailVerificationModal} from "../../redux/slices/modalSlice";
import {IonButtons, IonHeader, IonModal, IonToolbar} from "@ionic/react";
import TGIcon from "../TGElements/TGIcon";
import LottiePlayer from "../LottiePlayer/LottiePlayer";
import {TGButton, TGText} from "../TGElements";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const SuccessMailVerificationModal = () => {
  const {t} = useTranslation()
  const {successMailVerificationModal} = useSelector(state => state.modal)
  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch(setSuccessMailVerificationModal(false))
  }

  return (
    <IonModal
      isOpen={successMailVerificationModal}
      onDidDismiss={closeHandler}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top">
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={closeHandler}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end ConfirmMailModal'}>
        <LottiePlayer
          width={200}
          height={200}
          animationName="success-mail-animation"
        />
        <TGText
          color="var(--tg-primary-text)"
          fontSize={20}
          fontWeight="semibold"
          textAlign="center"
          width="100%"
          margin={"0 0 17px 0"}
        >
          {t("success-your-mail-verification")}
        </TGText>
        <TGText
          color={"var(--tg-secondary-text)"}
          fontSize={16}
          margin={"0 0 25px 0"}
          fontWeight="regular"
          textAlign={"center"}
          width="100%"
          cssClass={"confirm-mail-text"}
          textTransform={"none"}
        >
          {t("success-your-mail-verification-desc")}
        </TGText>
        <TGButton
          margin={"0 0 35px 0"}
          onButtonClick={closeHandler}
          backgroundColor={"var(--tg-dark)"}
          backgroundActiveColor={"var(--tg-black-active)"}
        >
          <TGText
            color={"var(--tg-white)"}
            fontSize={18}
            fontWeight="semibold"
          >
            {t("done")}
          </TGText>
        </TGButton>
      </div>
    </IonModal>
  );
};

export default SuccessMailVerificationModal;