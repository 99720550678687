//Helpers
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
//Components
import { TGButton, TGText } from "../TGElements";
import CardList from "./CardList";
import CardActionModal from "./CardActionModal";

const RegisteredCardsContent = () => {
    const {t} = useTranslation()
    const history = useHistory()
  return (
    <>
      <div className="RegisteredCardsContent">
        <CardList/>
        <TGButton
          additionalStyle={{
            position: "absolute",
            width: "calc(100% - 32px)",
            bottom: 30,
          }}
          onButtonClick={() => {
            history.push("/menu/payments/add-payment-method", {
              type: "add-new",
            });
          }}
        >
          <TGText color={"var(--tg-white)"}>{t("add-card")}</TGText>
        </TGButton>
      </div>
      <CardActionModal />
    </>
  );
};

export default RegisteredCardsContent;
