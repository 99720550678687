import {TGImg} from "../TGElements";
import React from "react";
import {IonItem} from "@ionic/react";
import {t} from "i18next";
import {format} from "date-fns";

const MembershipVehicle = ({vehicle, selectedVehicle, setSelectedVehicle = null, style = {}}) => {

    const onClickHandler = (vehicle) => {
        setSelectedVehicle && setSelectedVehicle(vehicle)
    }
    return (
        <IonItem lines={"none"} style={{...style, "--padding-start": "0px", "--padding-end": "0px","--inner-padding-end":"0px"}}
                 className={`VehicleItem ${vehicle.id === selectedVehicle?.id && "selected"}`}>
            <div onClick={() => onClickHandler(vehicle)} key={vehicle.id}
                 style={{
                     display: "flex",
                     width: "100%",
                     height: "100%",
                     position: "relative",
                     alignItems:"center",
                     mixBlendMode:vehicle.isPassive && "luminosity",
            }}>
                <div style={{textAlign:"end",marginRight:"14px",marginLeft:"-25px"}}>
                    <TGImg
                        src={vehicle.image}
                        width={130}
                        height={'auto'}
                        reflect={true}
                    />
                </div>
                <div style={{flexShrink:1}}>
                    <p className={"vehicleModel"}>{vehicle.model_text ? vehicle.model_text : t("unknown-model")}</p>
                    <p className={`vehiclePlate ${!vehicle.model_text && "noModel"}`}>{vehicle.plate}</p>
                </div>

                <div className={"vehicleInfo"}>
                    <div className={`Badge ${vehicle.type}`}>{t(vehicle.type)}</div>

                    {vehicle.isPassive && (
                        <p dangerouslySetInnerHTML={{
                            __html: t('removed-subscription', {
                                date: format(new Date(vehicle.availableUntil), 'dd.MM.yyyy - hh:mm')
                            })
                        }}></p>
                    )}
                </div>

            </div>
        </IonItem>

    );
};

export default MembershipVehicle;
