//Components
import SessionTop from "./SessionTop";
import SessionBottom from "./SessionBottom";
import SessionVehicle from "./SessionVehicle";
import { TGSpinner } from "../../../TGElements";
//Redux
import { useSelector } from "react-redux";

const SessionHero = () => {
  const {activeSession} = useSelector(state => state.session)

  return Object.keys(activeSession) ? (
    <div className="top-animated">
      <SessionTop />
      <SessionVehicle />
    </div>
  ) : <TGSpinner size={50} />;
};

export default SessionHero;
