import {TGBackButton, TGUser} from "../TGElements"
import {MenuContent} from "./MenuContent"
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter} from "@ionic/react";
import TGNotificationsButton from "../TGElements/TGNotificationsButton";
import EditProfileButton from "../EditProfileButton";

const Menu = () => {
  const {push} = useIonRouter()

  return (
    <IonPage className="MenuModal">
      <IonHeader className={"ion-no-border"}>
        <IonToolbar className={"TGPageHeader"}>

          <IonButtons slot="start">
            <TGBackButton onBack={() => push("/map",'back','pop')}/>
          </IonButtons>

          <IonButtons slot="end">
            <EditProfileButton />
            <TGNotificationsButton/>
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollY={true}
        className={"TGPageContent ion-padding-start ion-padding-end"}
        fullscreen={true}
      >
        <TGUser/>
        <MenuContent/>
      </IonContent>

    </IonPage>
  )
}

export default Menu