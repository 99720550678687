import {TGIcon, TGImg} from '../../TGElements';
import {OnboardingWallet} from '../../../assets/onboarding'
import {useTranslation} from 'react-i18next';

const Slide1 = ({activeIndex}) => {
    const {t} = useTranslation()

    return (
        <div className='OnboardingSlide'>
            <div style={{padding: '0 36px'}}>

                <div style={{textAlign: 'center'}}>
                    <TGImg src={OnboardingWallet}/>
                </div>

                <div
                    className={"OnboardingSlideWallet"}
                    dangerouslySetInnerHTML={{__html: t('onboarding-five-title')}}
                />

                <p> {t('onboarding-five-description')} </p>

                <div>
                    <div className={"walletList"}>
                        <TGIcon name="wallet-list1" size={31}/>
                        <span>{t('onboarding-five-list1')}</span>
                    </div>
                    <div className={"walletList"}>
                        <TGIcon name="wallet-list2" size={31}/>
                        <span>{t('onboarding-five-list2')}</span>
                    </div>
                    <div className={"walletList"}>
                        <TGIcon name="wallet-list3" size={31}/>
                        <span>{t('onboarding-five-list3')}</span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Slide1;
