//React
import { useEffect } from "react";
//Components
import {
  CompleteLater,
  TGAddVehicleButton,
  TGButton,
  TGPage,
  TGUser,
  VehiclesList,
} from "../components";
// Helpers
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { getVehicles } from "../helpers";
//Redux
import { useSelector } from "react-redux";

const AddVehicles = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { vehicles } = useSelector((state) => state.user);
  const {previousPath} = useSelector(state => state.global.route)
  const {keyboardHeight} = useSelector(state => state.device)
  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <TGPage
      cssClass={"AddVehiclesPage"}
      title={t("add-vehicle")}
      scrollY={false}
      backButton={previousPath === "menu/vehicles" ? true : false}
    >
      <TGUser />
      <TGAddVehicleButton
        onButtonClick={() => history.push("/menu/vehicles/enter-plate", {from: "menu/vehicles/add-vehicle"})}
        styles={{
          marginTop: 22,
          marginBottom: 22,
        }}
      />
      <VehiclesList />
      <TGButton
        disabled={!vehicles.length}
        additionalStyle={{
          position: "absolute",
          bottom: 60,
          width: "calc(100% - 32px)",
        }}
        onButtonClick={() => history.push("/map")}
      >
        {t("save-profile")}
      </TGButton>
      {(previousPath !== "menu/vehicles/add-vehicle/vehicle-preview" && keyboardHeight === 0) ? (
        <CompleteLater path={"/map"} additionalStyles={{
          position: "absolute",
          bottom: 30,
          width: "calc(100% - 32px)",
        }}/>
      ) : null}
    </TGPage>
  );
};

export default AddVehicles;
