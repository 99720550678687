//Ionic React
import { useRef, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setSearchModal } from "../../redux/slices/modalSlice";
//Components
import { TGModal } from "../TGElements";
import { SearchModalHeader, SearchModalContent } from "./components";
//Helpers
import { useTranslation } from "react-i18next";

const SearchModal = ({map}) => {
  const modalRef = useRef()
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { searchModal } = useSelector((state) => state.modal);
  const { mapFilter } = useSelector((state) => state.map);
  
  const [isModalFull, setModalFull] = useState(false)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(0.3)

  // search input placeholder by station type
  const searchInputPlaceHolder =
    mapFilter === "park-charge"
      ? t("search-park-charge")
      : mapFilter === "charge"
      ? t("search-charge-station")
      : t("search-park");

  return (
    <div
      ref={modalRef}
      className={`SearchModal ${searchModal.status && 'active'}`}
      onClick={(e) => {
        if (e.target.classList.contains("SearchModal")) {
          dispatch(setSearchModal(false));
        }
      }}
    >
      <div className={`SearchModalContent`}>
        <SearchModalHeader
          inputPlaceholder={searchInputPlaceHolder}
          map={map}
          isModalFull={isModalFull}
          setModalFull={setModalFull}
        />
        <SearchModalContent currentBreakpoint={currentBreakpoint}/>
      </div>
    </div>
  );
};
export default SearchModal;
