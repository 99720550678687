//Ionic & React
import { IonContent } from '@ionic/react'
//Redux
import { useDispatch, useSelector } from 'react-redux'
import { setRecentSearches } from '../../../../redux/slices/searchSlice'
// Components
import SearchResultsItem from './SearchResultsItem'
//Helpers
import { getStationDetail } from '../../../../helpers'

const SearchResultsList = () => {
    const {searchResults, recentSearches} = useSelector(state => state.search)
    const dispatch = useDispatch()
    const handleClick = (resultItem) => {
        let station = resultItem
        addRecentSearches(station)
        getStationDetail(station, true, "search")
    }
    const addRecentSearches = (searchItem) => {
            if(recentSearches.find(recent => searchItem.id === recent.id )) {
                return;
            }
            dispatch(setRecentSearches(searchItem))
        }
    return (
        <div className='SearchResultsList'>
            <IonContent style={{"--background":"transparent"}}>
            {
                searchResults.map(result => (
                    <SearchResultsItem
                    key={result.id}
                    result={result}
                    handleClick={() => handleClick(result)}
                    />
                ))
            }
            </IonContent>
        </div>
    )
}

export default SearchResultsList