import React from 'react'
import {useTranslation} from 'react-i18next'
import {TGPage} from "../components";
import {api, getVehicles} from "../helpers";
import ParkListDropdown from "../components/Membership/ParkListDropdown";
import ParkDetailContainer from "../components/Membership/ParkDetailContainer";
import {useSelector,useDispatch} from "react-redux";
import {setAllParks, setSelectedPark} from "../redux/slices/membershipSlice";
import {useIonViewDidEnter} from "@ionic/react";
import {useParams} from "react-router";

const Membership = () => {
    const params = useParams()
    const {selectedPark} = useSelector(state => state.membership)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useIonViewDidEnter(() => {
        getVehicles();
        api.getParkList().then((r) => {
            r.data?.data && dispatch(setAllParks(r.data.data))
        });

        if (params.id) {
            dispatch(setSelectedPark(Number(params.id)))
        }
    }, []);

    return (
        <TGPage
            title={t("membership")}
            scrollY={false}
            backButton={true}
            className={"Membership"}
        >

            <ParkListDropdown/>

            {selectedPark && <ParkDetailContainer/>}

        </TGPage>
    )
}

export default Membership