import { createSlice } from "@reduxjs/toolkit"

export const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState: {
    activeSession: {},
    selectedPaymentMethod: 1, //  1: Card, 2: Wallet
    selectedCompanyId: null,
  },
  reducers: {
    setActiveSession: (state, action) => {
        state.activeSession = action.payload
    },
    setSelectedPaymentMethod: (state, action) => {
        state.selectedPaymentMethod = action.payload
    },
    setSelectedCompanyId: (state, action) => {
        state.selectedCompanyId = action.payload
    },
    clearSelectedCompanyId: (state) => {
        state.selectedCompanyId = null
    },
    clearSelectedPaymentMethod: (state) => {
        state.selectedPaymentMethod = 1
    },
  },
})

export const {
    setActiveSession,
    setSelectedPaymentMethod,
    setSelectedCompanyId,
    clearSelectedPaymentMethod,
    clearSelectedCompanyId
} = sessionSlice.actions
export default sessionSlice.reducer
