import React, {useEffect, useState} from 'react';
import {TGIcon, TGPage, TGText} from "../components";
import {api} from "../helpers";
import {TGVirtualScroll} from "../components/TGElements";
import {format} from "date-fns";
import {enGB, tr} from "date-fns/locale";
import {useTranslation} from "react-i18next";

const ChargingHistory = () => {
  const {t, i18n} = useTranslation()
  const [historyData, setHistoryData] = useState([])

  const getChargeHistory = async () => {
    // API call
    const res = await api.getChargeHistoryWithStation()
    if (res.status === 200) {
      setHistoryData(res.data.response)
    }
  }


  useEffect(() => {

    getChargeHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <TGPage
      title={t("charging-history")}
      backButton={true}
      scrollY={false}
    >

      <TGText fontWeight={600} fontSize={18} textTransform={"none"} margin={'20px 0 10px'}>
        {t('last-operations')}
      </TGText>

      <TGVirtualScroll
        data={historyData}
        item={(index, data) => (
          <div key={index} className="ChargingHistoryItemWrapper">

            <div className="detail">
              <div className={"iconWrapper"}>
                <TGIcon name={"charge-transaction"} size={18} fill={"#18C941"}/>
              </div>
              <div className={"center-side"}>
                <TGText wrapWithDots={true} fontSize={14} fontWeight={500} margin={'0 0 4px'}>
                  {data.station_name}
                </TGText>
                <div className={"transaction-wrapper"}>
                  <TGText fontSize={14} fontWeight={500} color={'var(--tg-secondary-text)'}>
                    {data.connection_type}
                  </TGText>
                  <span className={"transaction-dot"}></span>
                  <TGText fontSize={14} fontWeight={500} color={'var(--tg-primary-text)'}>
                    {format(data.transaction_date, "d MMM-kk:mm",{locale:i18n.language === 'tr' ? tr : enGB})}
                  </TGText>

                </div>
              </div>

              <div className={"right-side"}>
                <TGText textAlign={"end"} width={'100%'} margin={'0 0 4px'} fontSize={16} fontWeight={600}>
                  {data.total_price}₺
                </TGText>
                <TGText fontSize={14} fontWeight={500} color={'var(--tg-secondary-text)'}>
                  {data.total_consumption} kWh
                </TGText>
              </div>

            </div>

          </div>
        )}
      >
      </TGVirtualScroll>
    </TGPage>
  );
};

export default ChargingHistory;