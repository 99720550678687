import { useTranslation } from "react-i18next";
import { TGButton } from "../../TGElements";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

const AddNewButton = () => {
  const { t } = useTranslation();
  const {vehicles} = useSelector(state => state.user)
  const history = useHistory();
  return (
    <TGButton
      onButtonClick={() => history.push("/menu/vehicles/enter-plate")}
      additionalStyle={{
        position: "absolute",
        bottom: vehicles.length ? "5%" : "10%",
        width: "calc(100% - 30px)",
      }}
    >
      {t("add-vehicle")}
    </TGButton>
  );
};

export default AddNewButton;
