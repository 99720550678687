//Helpers
import {stopSession} from "../../../../helpers";
import {useTranslation} from "react-i18next";
//Components
import {TGButton, TGText} from "../../../TGElements";
import {useDispatch} from "react-redux";
import { setLoading } from "../../../../redux/slices/globalSlice";
import {useIonRouter} from "@ionic/react";
import SessionBottom from "./SessionBottom";

const ActionButton = ({setIntervalStart}) => {
    const {t} = useTranslation();
    const history = useIonRouter()
    const dispatch = useDispatch()

    const handleClick = async () => {
        setIntervalStart(false)
        dispatch(setLoading({status: true, message: t("charge-ending")}))
        const stop = await stopSession();
        dispatch(setLoading(false))
        if (stop) {

            history.push("/receipt", 'forward','pop',{
                receipt: stop.response
            });
        }
    };


    return (
        <div style={{position: "absolute", bottom: 20, width: "calc(100% - 32px)"}}>
            <SessionBottom />
            <TGButton
                backgroundColor={"var(--tg-primary-text)"}
                backgroundActiveColor={"var(--tg-black-active)"}
                width={"100%"}
                margin={"25px 0 0 0"}
                onButtonClick={handleClick}>
                <TGText color={"var(--tg-white)"} fontSize={16} fontWeight={"medium"}>
                    { t("stop-charge")}
                </TGText>
            </TGButton>
            <TGButton
              backgroundColor={"var(--tg-white)"}
              backgroundActiveColor={"var(--tg-secondary-text)"}
              width={"100%"}
              margin={"10px 0 0 0"}
              onButtonClick={() => history.push("/menu/support/send-ticket")}
              additionalStyle={{
                border: "1px solid var(--tg-primary-text)",
                borderRadius: "var(--border-radius)",
              }}
            >
                <TGText color={"var(--tg-primary-text)"} fontSize={16} fontWeight={"medium"}>
                    { t("ticket")}
                </TGText>
            </TGButton>
        </div>
    );
};

export default ActionButton;
