//React
import { useState } from 'react';
//Components
import { TGButton, TGInput, TGText } from '../TGElements'
import Cards from 'react-credit-cards-2';
//Helpers
import { useValidation } from '../../hooks';
import {createCard, formatValue, getPaymentMethods} from '../../helpers';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router';
import { Alert } from '../../helpers/alert';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setActionSuccessModal } from '../../redux/slices/modalSlice';

const AddPaymentMethodForm = ({
  card,
  type,
  setCard
}) => {

  const history = useHistory()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const validate = useValidation()
  const {user} = useSelector(state => state.user)
  const {keyboardHeight} = useSelector(state => state.device)
  const [errors, setErrors] = useState([
    {type: 'card-number', message: "", success: true},
    {type: 'expiry', message: "", success: true},
    {type: 'cvc', message: "", success: true},
    {type: 'name', message: "", success: true}
  ])

  const handleInputChange = async (evt) => {
    const { value } = evt.detail;
    const { name } = evt.srcElement;
    const formatted = await formatValue(name, value);
    setCard((prev) => ({ ...prev, [name]: formatted }));
    if (name !== "number") {
      handleValidate({ type: name, data: formatted })
    }
  }
  const handleInputFocus = (evt) => setCard((prev) => ({ ...prev, focus: evt.detail.srcElement.name }))
  const handleSubmit = async (e) => {
    e.preventDefault();
    const creditCard = {
      card_number: card.number,
      cvc: card.cvc,
      expire_month: card.expiry.slice(0,2),
      expire_year: `20${card.expiry.slice(3,5)}`,
      name: card.name
    }

    const values = Object.values(creditCard);
    for (let i = 0; i < values.length; i++) {
      if (values[i] === "") {
        Alert.error(t("fill-form"));
        return;
      }
    }


    const {success, message} = await createCard(user.info.email, creditCard)
    if(!success) {
      Alert.error(message)
      return;
    }
    dispatch(
      setActionSuccessModal({
        status: true,
        action: "add-card",
        successText: t("card-successfully-added")
      })
    );
    getPaymentMethods()
    history.goBack();
    Alert.success(t("card-successfully-added"))
  }

  const handleValidate = (object) => {
    if(object.type !== "card-number"){
      const err = validate(object)
      const newState = errors.map(obj => {
        if (err.type && obj.type === err.type) {
          return {...obj, message: err.message, success: err.success};
        }
        return obj;
      });
      setErrors(newState);
    }
  }

  return (
    <div className={`CreditCardFormWrapper ${keyboardHeight ? "keyboard" : ""}`}>
      <Cards
        name={card.name}
        number={card.number}
        cvc={card.cvc}
        expiry={card.expiry}
        focused={card.focus}
      />
    <form onSubmit={handleSubmit} className='CreditCardForm' noValidate>
        <TGText fontWeight={"medium"} fontSize={16} margin={"16px 0 0 0"}>{t("card-number")}</TGText>
        <TGInput
          type={"tel"}
          name={"number"}
          value={card.number}
          borderRadius={15}
          onInputChange={handleInputChange}
          onInputFocus={handleInputFocus}
          onInputBlur={() => handleValidate({ type: "card-number", data: card.number })}
          inputmode="numeric"
          maxlength={19}
          placeholder="**** **** **** ****"
          error={!(errors?.find(err => err.type === "card-number").success)}
          errorText={t(errors.find(err => err.type === "card-number").message || "")}
        />
        <TGText fontWeight={"medium"} fontSize={16} margin={"10px 0 0 0"}>{t("name-surname")}</TGText>
        <TGInput
          type={"text"}
          name={"name"}
          value={card.name}
          borderRadius={15}
          onInputChange={handleInputChange}
          onInputFocus={handleInputFocus}
          onInputBlur={() => handleValidate({ type: "name", data: card.name })}
          error={!(errors?.find(err => err.type === "name").success)}
          errorText={t(errors.find(err => err.type === "name").message || "")}
          placeholder={t("name-surname")}
        />

        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 10,
            margin: "10px 0 0 0"
          }}>
          <div style={{
            width: "100%"
          }}>
            <TGText fontWeight={"medium"} fontSize={16}>{t("expire-date")}</TGText>
            <TGInput
              type={"tel"}
              name={"expiry"}
              value={card.expiry}
              borderRadius={15}
              onInputChange={handleInputChange}
              onInputFocus={handleInputFocus}
              onInputBlur={() => handleValidate({ type: "expiry", data: card.expiry })}
              inputmode="numeric"
              pattern={/\d{2}\/\d{2}/gm}
              maxlength={5}
              error={!(errors?.find(err => err.type === "expiry").success)}
              errorText={t(errors.find(err => err.type === "expiry").message || "")}
              placeholder={"MM/YY"}
            />
          </div>
          <div style={{
            width: "100%"
          }}>
            <TGText fontWeight={"medium"} fontSize={16}>CVC</TGText>
            <TGInput
              type={"tel"}
              name={"cvc"}
              value={card.cvc}
              borderRadius={15}
              onInputChange={handleInputChange}
              onInputFocus={handleInputFocus}
              onInputBlur={() => handleValidate({ type: "cvc", data: card.cvc })}
              pattern={/[0-9]{3}/}
              maxlength={3}
              error={!(errors?.find(err => err.type === "cvc").success)}
              errorText={t(errors.find(err => err.type === "cvc").message || "")}
              placeholder={"***"}
            />
          </div>
        </div>
        <TGButton
          additionalStyle={{
            position: "absolute",
            width: "calc(100% - 32px)",
            bottom: 16,
            zIndex: 1
          }}
          type="submit"
          disabled={errors.find(err => err.success === false)}
        >
          <TGText color={"var(--tg-white)"}>{type === "edit" ? t("edit-card") : t("add-card")}</TGText>
        </TGButton>
      </form>
      </div>
  )
}

export default AddPaymentMethodForm
