import {useHistory} from "react-router"
import {CallCustomerServices, TGIcon, TGItem, TGList, TGPage} from "../components"
import {t} from 'i18next'
import uniqid from "uniqid";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setMenuModal} from "../redux/slices/modalSlice";

const AboutGrouped = () => {
    const history = useHistory()
    const SupportMenuItems = [
        { id: uniqid(), slug: "about", leftIcon: {width: 19.5, height: 19.5} },
        { id: uniqid(), slug: "agreements", leftIcon: {width: 15.7, height: 21} },
    ]
    const LeftIcon = ({...props}) => {
        return (
            <div className='TGItemIconWrapper'>
                <TGIcon {...props}/>
            </div>
        )
    }

    return (
        <TGPage
            title={t("support")}
            scrollY={false}
            backButton={true}
            ionPadding={false}
            cssClass={"ion-padding-start ion-padding-end"}
        >
            <TGList className="Menu">
                {SupportMenuItems.map(item => (
                    <TGItem
                        key={item.id}
                        slug={item.slug}
                        handleClick={() => history.push(`/menu/about-grouped/${item.slug}`)}
                        leftIcon={<LeftIcon name={item.slug} width={item.leftIcon.width}
                                            height={item.leftIcon.height}/>}
                    />
                ))}
            </TGList>
            <CallCustomerServices/>
        </TGPage>
    )
}

export default AboutGrouped