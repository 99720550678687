//Ionic & React
import { useEffect, useState } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import { SetUser } from "../components/Register";
import {ConfirmMailModal, TGPage} from "../components";
//Helpers
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const EditUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);
  const {previousPath} = useSelector(state => state.global.route)
  const isBackButton = previousPath?.includes("register")

  useEffect(() => {
    if (isDone) {
      if(!previousPath.includes("register")){
        history.goBack()
      } else {
        history.push("/menu/vehicles/add-vehicle")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  return (
    <TGPage
      title={t("edit-profile")}
      scrollY={false}
      backButton={!isBackButton}
      onBack={() => history.go(-1)}
    >
      <SetUser setIsDone={setIsDone} />
    </TGPage>
  );
};

export default EditUser;
