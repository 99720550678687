import {CompaniesList, TGButton, TGPage} from "../components";
import {useTranslation} from "react-i18next";
import {IonModal} from "@ionic/react";
import {useEffect, useState} from "react";
import {createCompany, deleteCompany, getMyCompanies} from "../helpers";
import CreateCompanyContent from "../components/CompaniesList/CreateCompanyContent";
import {Alert} from "../helpers/alert";

const Corporates = () => {
    const {t} = useTranslation()
    const [companies, setCompanies] = useState([]);
    const [modal, setModal] = useState(false);
    const [addingTaxError, setAddingTaxError] = useState(false)

    useEffect(() => {
        getCompany();
    }, [])

    const getCompany = () => {
        getMyCompanies().then(({data}) => setCompanies(data.filter((c)=>c.company)))
    }

    const addCompany = (taxNumber) => {
        if (!taxNumber) {
            Alert.error(t("enter-tax-number"));
            setAddingTaxError(true)
            return;
        }
        if (companies.find(({company}) => company.company_code === taxNumber)) {
            setAddingTaxError(true)
            Alert.warning(t("company-already-exists"));
            return;
        }

        createCompany({'company_code': taxNumber}).then(({response}) => {
            if (Object.keys(response).length) {
                setCompanies([...companies, response])
                setAddingTaxError(false)
                setModal(false)
            }
        })
    }

    const removeCompany = (id) => {
        deleteCompany(id).then(() => {
            setCompanies(companies.filter(({company}) => company.id !== id))
        })
    }

    return (
        <TGPage backButton={true} title={t("corporate-account")}>
            <div className={"corporates"}>
                <CompaniesList companies={companies} deleteHandler={removeCompany}/>

                <TGButton
                    styles={{width: "100%"}}
                    onButtonClick={() => setModal(true)}
                    additionalStyle={{flex: "0 0 auto"}}
                >
                    {t("add-company")}
                </TGButton>

                <IonModal
                    isOpen={modal}
                    breakpoints={[.5]}
                    initialBreakpoint={.5}
                    onDidDismiss={() => setModal(false)}
                >
                    <CreateCompanyContent setModal={setModal} createCompany={addCompany} isError={addingTaxError}/>
                </IonModal>
            </div>
        </TGPage>
    );
}

export default Corporates;
