//Redux
import {useDispatch, useSelector} from 'react-redux'
import {setSearchModal} from '../../../../redux/slices/modalSlice'
//Components
import {TGFabButton, TGIcon, TGSearchInput} from '../../../TGElements'
import {setSearchLoading, setSearchResults, setSearchText} from '../../../../redux/slices/searchSlice'
import React, {memo, useEffect, useState} from 'react'
import {getLocation, getStations} from '../../../../helpers'
import {Alert} from '../../../../helpers/alert'
import {t} from 'i18next'
import {navigateCircle} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

const SearchModalHeader = ({inputPlaceholder, map, setModalFull, isModalFull}) => {

  const dispatch = useDispatch()
  const {searchText} = useSelector(state => state.search)
  const {userPosition} = useSelector(state => state.map)

  const handleFocus = (isFocus = true) => {
    if (!isFocus) {
      setModalFull(false)
    } else {
      if (!isModalFull) {
        // modalRef?.current?.setCurrentBreakpoint(1)
        setModalFull(true)
      }
    }
  }

  useEffect(() => {
    if (searchText === "") {
      dispatch(setSearchResults([]))
      return;
    }

    dispatch(setSearchLoading(true))
    const timer = setTimeout(() => {
      search()
    }, 1000);
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const search = async () => {
    const {lat, lng} = userPosition
    getStations(lat, lng, searchText)
      .then(stations => dispatch(setSearchResults(stations)))
      .catch(() => Alert.error(t("try-again")))
      .finally(() => dispatch(setSearchLoading(false)))
  }

  const NavIcon = () => (
    <IonIcon
      icon={navigateCircle}
      style={{
        height: "100%",
        width: "35px",
        flexShrink: 0,
        marginRight: "3px",
      }}
      onClick={async ()=>{
        dispatch(setSearchModal(false))
        dispatch(setSearchText(''))
        const coords = await getLocation();
        map.map.setCenter(coords)
        map.map.setZoom(15)
      }}
      color={"medium"}
    />
  )

  const closeSearchModal = () => {
    dispatch(setSearchModal(false))
  }

  return (
    <div className="SearchModalHeader">
      <TGSearchInput
        type="search-modal"
        placeholder={inputPlaceholder}
        onSearchTextChange={e => dispatch(setSearchText(e.target.value))}
        onInputFocus={handleFocus}
        onInputBlur={() => handleFocus(false)}
        borderRadius={'30px'}
        lastIcon={<NavIcon/>}
        value={searchText}
      />

      <TGIcon name={"close-modal"} size={30} onClick={closeSearchModal}/>

      {/* <TGFabButton/> */}
    </div>
  );
}

export default memo(SearchModalHeader)