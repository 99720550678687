import React, {useState} from 'react';
import {IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar, useIonViewDidEnter} from "@ionic/react";
import TGIcon from "../../TGElements/TGIcon";
import {TGText} from "../../index";
import {useTranslation} from "react-i18next";
import {getActiveSession, getMyCompanies, transactionUpdate} from "../../../helpers";
import {useHistory} from "react-router-dom";
import {setSelectedCompanyId} from "../../../redux/slices/sessionSlice";
import {useDispatch, useSelector} from "react-redux";
import {setChangeInvoiceTypeModal, setChangePaymentMethodModal} from "../../../redux/slices/modalSlice";

const SelectInvoiceTypeModal = () => {
  const {t} = useTranslation()
  const {invoiceTypeModalStatus:isActive} = useSelector(state => state.modal.changePaymentMethodModal)
  const {activeSession} = useSelector(state => state.session)
  const [corporateInvoices, setCorporateInvoices] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()

  const clickAddPaymentMethod = () => {
    dispatch(setChangeInvoiceTypeModal(false))
    dispatch(setChangePaymentMethodModal(false))
    history.push('/menu/payments/corporate-account')
  }


  const changeCompanyId = (id) => {
    dispatch(setSelectedCompanyId(id))
    transactionUpdate(activeSession.id, id).then(() => {
      dispatch(setChangeInvoiceTypeModal(false))
      getActiveSession()
    })
  }

  useIonViewDidEnter(() => {
    getMyCompanies().then(({data}) => {
      setCorporateInvoices(data.filter(c=>c.company))
    })
  }, []);

  return (
    <IonModal
      onDidDismiss={() => dispatch(setChangeInvoiceTypeModal(false))}
      isOpen={isActive}
      showBackdrop={true}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top ion-padding-bottom">
          <IonTitle className="ion-no-padding ion-text-capitalize">{t('select-corporate-invoice')}</IonTitle>
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={() => dispatch(setChangeInvoiceTypeModal(false))}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'TGPageContent ion-padding-start ion-padding-end'}>
        {
          corporateInvoices.map(({company}, index) => (
            <div
              key={index}
              className={`InvoiceWrapper`}
              onClick={() => changeCompanyId(company.id)}
            >

              <div className={"InvoiceList"}>
                <div className={"InvoiceListItem"}>
                  <TGText fontSize={16} fontWeight={600}>{t('company-name')}</TGText>
                  <TGText fontSize={12}>{t('short-tax-number')}</TGText>
                </div>

                <div className={"InvoiceListItem"}>
                  <TGText fontSize={16} fontWeight={600}>:</TGText>
                  <TGText fontSize={12}>:</TGText>
                </div>

                <div className={"InvoiceListItem"}>
                  <TGText fontSize={16} fontWeight={600} width={'190px'}
                          wrapWithDots={true}>{company?.company_name}</TGText>
                  <TGText fontSize={12}>{company?.company_code}</TGText>
                </div>
              </div>


              {activeSession.company_id === company.id ? (
                  <TGIcon
                    name={"circle-green-tick"}
                    width={25}
                    height={25}
                    style={{marginLeft: "auto"}}
                  />
                ) : <div className={"empty-circle"}></div>
              }

            </div>
          ))
        }

        <div className={`CardWrapper no-bg`} onClick={clickAddPaymentMethod}>
          <div className={"CardDetail"}>
            <TGText fontSize={16} fontWeight={600}>{t("add-corporate-invoice")}</TGText>
          </div>
          <TGIcon
            name={"circle-dark-plus"}
            width={40}
            height={40}
            style={{marginLeft: "auto"}}
          />
        </div>

      </div>

    </IonModal>
  );
};

export default SelectInvoiceTypeModal;