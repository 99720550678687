import {TGButton, TGText} from './TGElements';
import {useTranslation} from 'react-i18next';
import {logout} from '../helpers';
import {useHistory} from "react-router-dom";
import {useIonRouter} from "@ionic/react";

const Logout = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const onLogout = async () => {
    const response = await logout()
    if (response) {
      history.go(0)
    }
  }

  const isDeleteAccountActive = process.env.REACT_APP_DELETE_ACCOUNT_ACTIVE === 'true'

  return (
    <div className="LogoutWrapper">
      <TGButton
        width={"fit-content"}
        height={20}
        backgroundColor={"var(--tg-transparent)"}
        backgroundActiveColor={"var(--tg-transparent)"}
        onButtonClick={onLogout}
      >
        <TGText color={"var(--tg-link)"} fontSize={14} fontWeight={"regular"}>
          {t("logout")}
        </TGText>
      </TGButton>

      {isDeleteAccountActive && (
        <TGButton
          width={"fit-content"}
          height={20}
          backgroundColor={"var(--tg-transparent)"}
          backgroundActiveColor={"var(--tg-transparent)"}
          onButtonClick={onLogout}
        >
          <TGText color={"var(--tg-error)"} fontSize={14} fontWeight={"regular"}>
            {t("remove-user")}
          </TGText>
        </TGButton>
      )}
    </div>
  );
}

export default Logout