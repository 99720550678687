import { useHistory } from "react-router";
import { TGIcon, TGImg, TGText } from "../TGElements";
import { IonItemOption, IonItemOptions, IonItemSliding, IonItem } from "@ionic/react";
import {t} from "i18next"
import { removeVehicle } from "../../helpers";
const VehicleItem = ({ vehicle }) => {
  const history = useHistory();
  return (
    <IonItemSliding className="VehicleSlidingWrapper">
      <IonItem className="VehicleItem ion-no-padding" lines="none">
      <div className="VehicleItemInner">
        <TGImg src={vehicle.logo} size={60} />
        <div className="VehicleItemRight">
          <TGText fontSize={16} fontWeight="semibold">
            {vehicle.brand_name}
          </TGText>
          <TGText
            fontSize={14}
            fontWeight="regular"
            color="var(--tg-secondary-text)"
            width={180}
            wrapWithDots={true}
          >
            {vehicle.model_text}
          </TGText>
          <TGText fontSize={16} fontWeight="bold" color="var(--tg-primary)">
            {vehicle.plate}
          </TGText>
        </div>
        <div
          className="IconWrapper"
          style={{ margin: 0 }}
          onClick={() => {
            history.push("/menu/vehicles/enter-plate", {
              vehicleToEdit: vehicle,
              from: "vehicles"
            });
          }}
        >
          <TGIcon name="car-added" size={18} />
        </div>
      </div>
      </IonItem>
    <IonItemOptions side="end">
      <IonItemOption
        expandable={true}
        onClick={() => removeVehicle(vehicle.plate)}
        color="danger"
      >
        <div className="VehicleSlidingOption">
          <TGIcon name="remove-vehicle" />
          <TGText color={"var(--tg-white)"} fontSize={12} fontWeight={400} margin={"0 5px 0 0"}>
            {t("delete")}
          </TGText>
        </div>      
      </IonItemOption>
    </IonItemOptions>
  </IonItemSliding>
  );
};

export default VehicleItem;
