import {StartSessionForm, TGPage, TGText, TGTooltip} from "../components";
import {useTranslation} from "react-i18next";

const StartSession = () => {
    const {t} = useTranslation();

    return (
        <TGPage
            toolbar={true}
            cssClass={"StartSession"}
            backButton={true}
            scrollY={false}
            fullscreen={true}
        >
            <div className="StartSessionContent">
                <div className={"StartSessionHeader"}>
                    <TGText fontWeight={600} textAlign={"center"} margin={"0px auto"} textTransform={"unset"}>
                        {t("manual-charge")}
                    </TGText>

                    <div className="TooltipText">
                        <TGText
                            width={"fit-content"}
                            fontWeight={400}
                            fontSize={16}
                            color={"var(--tg-primary-text)"}
                            textAlign={"center"}
                            textTransform={"unset"}
                            dangerouslySetInnerHTML={{__html: t("enter-4digit-code")}}
                        />
                        <TGTooltip id="scan-tooltip">
                            <TGText fontSize={12} textTransform={"unset"}>
                                {t("charge-code-tip")}
                            </TGText>
                        </TGTooltip>
                    </div>
                </div>

                <StartSessionForm/>
            </div>
        </TGPage>
    );
};

export default StartSession;
